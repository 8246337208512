
.cookie-popup {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: 0;
  transition: max-height 250ms ease-in-out;
  z-index: 200;
  background: rgba($yellow-500, 0.9);

  &.is-expanded {
    max-height: 200px;
  }
}

.cookie-popup__wrapper {
  padding-bottom: 15px;

  @include media-breakpoint-up(md) {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding-bottom: 0;
  }

  .button {
    margin-left: 0;
    width: 100%;
    height: 36px;

    @include media-breakpoint-up(md) {
      margin-left: 20px;
      width: auto;
    }
  }
}

.cookie-popup__content {
  margin: 0;
  padding: 15px 0;

  a {
    color: inherit;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
