@import "mixins/breakpoints";

.faceted-product-list__grid {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  gap: 0.5em;
  grid-template-areas:
    "header"
    "main"
    "bottom";

  @include media-breakpoint-up(md) {
    grid-template-columns: 3fr 12fr;
    gap: 1.5em;
    grid-template-rows: min-content 1fr;
    grid-template-areas:
    "filter-list header"
    "filter-list main"
    "bottom bottom";
  }

  @include media-breakpoint-up(xl) {
    grid-template-columns: 2fr 12fr;
  }

  &.no-facets {
    grid-template-columns: auto;
    grid-template-areas:
      "header"
      "main"
      "bottom";

      // forces sort by to stay on the right
    .faceted-product-list__filter-sort {
      justify-content: flex-end;
    }
  }
}

.faceted-product-list__header { grid-area: header; }
.faceted-product-list__main { grid-area: main; }
.faceted-product-list__sidebar { grid-area: filter-list; }
.faceted-product-list__bottom { grid-area: bottom; }

.faceted-product-list {
  padding: 1em 15px;

  @include media-breakpoint-up(md) {
    padding: 2em 30px;
  }
}


// Layout of main sections

.faceted-product-list__category {
  display: flex;
  flex-grow: 1;
  flex-flow: row wrap;
}

.faceted-product-list__sidebar {
  display: none;

  @include media-breakpoint-up(md) {
    display: block;
    // aligns sidebar with breadcrumbs while retaining big click target
    margin-top: -20px;
  }

  &.active {
    display: block;
  }
}

.faceted-product-list__main {
  position: relative;
  margin: 0;
}


// Header section

.faceted-product-list__breadcrumbs {
  display: none;

  @include media-breakpoint-up(sm) {
    display: block;
    padding-bottom: 5px;
  }
}

.faceted-product-list__title {
  text-align: left;

  h1 {
    margin-top: 0;
    text-transform: uppercase;
    font-weight: 900;
  }

  p {
    margin: 0;

    @include media-breakpoint-up(md) {
      max-width: 900px;
    }
  }
}

.faceted-product-list__filter-toggle {

  @include media-breakpoint-up(md) {
    display: none;
  }

  .button--link {
    text-decoration: none;
    font-size: 1.8rem;
    font-weight: 700;
  }
}

.faceted-product-list__applied-facets {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-wrap: wrap;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    align-items: center;
    flex-basis: 80%;
  }
}

.faceted-product-list__filter-sort {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 100%;
  margin-top: 0.5em;

  @include media-breakpoint-up(md) {
    align-self: flex-start;
    flex: 0;
    margin-top: 0;
    margin-left: auto;
  }

  .sorting-button {
    margin-left: 5px;
    padding-top: 8px;
    padding-bottom: 8px;
    vertical-align: middle;
  }
}

.faceted-product-list.has-js {
  .sorting-button {
    display: none;
  }
}

.faceted-product-list__result-count {
  font-size: 1.4rem;
  font-weight: 700;
  color: $gray-600;

  @include media-breakpoint-up(md) {
    display: inline-block;
  }
}


// Main section

.faceted-product-list__list-header {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 0.5em;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    align-items: center;
    flex-grow: 0;
    margin-bottom: 0;
  }

  &:empty {
    margin: 0;
  }

  .button {
    font-size: 1.6rem;
  }
}

.faceted-product-list__list {
  margin: 0 -2px;

  @include media-breakpoint-up(sm) {
    margin: 0 -10px;
  }
}

.faceted-product-list__empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 600px;
  margin-top: 15px;
  text-align: center;
  border-radius: 2px;
  background-color: $gray-200;
  color: $gray-700;

  @include media-breakpoint-up(sm) {
    justify-content: center;
  }

  @include media-breakpoint-up(md) {
    min-height: 900px;
    margin-top: 0;
  }

  h2 {
    margin: 10px auto;
  }

  svg {
    width: 3em;
    height: 3em;
    margin-top: 9em;

    @include media-breakpoint-up(sm) {
      margin-top: 0;
    }
  }
}

.faceted-product-list__loading {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: $white;
}

.faceted-product-list__list-footer {
  margin: 3em 0;
  text-align: center;
}


// Loading state

.faceted-product-list.is-loading {
  .faceted-product-list__loading {
    display: flex;
    flex-wrap: wrap;
  }
  .catalog-pagination__link {
    pointer-events: none;
  }
}


// Facet List - Mobile

.mobile-filter__header {
  @include media-breakpoint-up(sm) {
    display: none;
  }

  h2 {
    margin-top: 0;
  }
}

.mobile-filter__category-list {
  @include media-breakpoint-up(sm) {
    display: none;
  }
}

.mobile-filter__close {
  position: absolute;
  top: 0;
  right: 0;
  width: unset !important;
  border: 0;
  text-align: right;
  font-size: 3rem;
  font-weight: 400;

  &:hover {
    background: none;
    color: $black;
  }

  @include media-breakpoint-up(sm) {
    display: none;
  }
}

.mobile-filter__apply {
  @include media-breakpoint-up(sm) {
    display: none;
  }
}

.faceted-product-list.has-sidebar-overlay {
  .faceted-product-list__sidebar {
    display: block;
    position: fixed;
    z-index: 2400;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 20px;
    background-color: $white;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .mobile-filter__header,
  .mobile-filter__category-list,
  .mobile-filter__close,
  .mobile-filter__apply {
    display: block;
  }
}
