.sorting-dropdown {
  position: relative;
  vertical-align: middle;
  display: inline-flex;
  align-items: center;
  margin-bottom: 0.5em;
}

.sorting-dropdown__label {
  position: absolute;
  top: -10px;
  left: 10px;
  z-index: 1;
  margin-right: 10px;
  margin-bottom: 0;
  padding: 0 4px;
  background-color: $white;
  font-weight: normal;
  font-size: 1.4rem;
}

.sorting-dropdown__wrapper {
  position: relative;
}

.sorting-dropdown__arrow {
  position: absolute;
  transform: translateY(-50%);
  right: 1em;
  top: 50%;
  pointer-events: none;

  svg {
    width: auto;
    height: 12px;
  }
}

.sorting-dropdown__select {
  background-color: $white;
  color: $gray-900;
  margin: 0;
  padding: 0.5em;
  padding-right: 2em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 2px solid $gray-300;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  line-height: 1.5;
  transition: border-color 100ms ease;

  &:hover,
  &:focus {
    border-color: $gray-900;
  }

  &::-ms-expand {
    display: none;
  }
  &[disabled] {
    color: rgba(0, 0, 0, 0.25);
  }
}
