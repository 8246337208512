body, input, textarea, select, button {
  // Safari 13 can crash hard on optimizeLegibility on select boxes
  // text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga', 'kern';
}

html {
  background: $white;
  //font-size: 55%;
  backface-visibility: hidden;

  //@include media-breakpoint-up(md){
  //  font-size: 62.5%;
  //}
}

body {
  //font-size: 1.6rem;
  backface-visibility: hidden;
  background: $white;

  &.modal-open {
    overflow: auto!important;
    padding-right: 0!important;
  }
}

#message {
  position: relative;
  z-index: 1001;
  background: $blue-500;
  color: $white;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;

  p {
    margin: 0;
    padding: .5em;
  }
}

#main {
  position: relative;
  margin: 0 auto;
  background-color: $white;
  backface-visibility: hidden;
}

.fluid-img {
  display: block;
  width: 100%;
  height: auto;
}

.pixelated {
  image-rendering: pixelated;
}

.fluid-video {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;;
}

.fluid-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.filer_image {
  display: inline-block;
  position: relative;

  img {
    max-width: 100%;
  }
}

#basic-modal {
  padding: 0!important;
  img {
    max-width: 100%;
  }
}

#cms_toolbar .cms_submenu-dropdown { /* bug in plugin selection dropdown */
  max-height: 250px;
  padding-bottom: 20px;
}

// Class for usability. Hides content to regular users but makes it
// accessible to users with screen readers.
.element-invisible {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px,1px,1px,1px);
}

// Basic responsive image rules for
// using on inline images.
.responsive-image {
  height: auto;
  max-width: 100%;
}

// Horizontal stack class
// Meant mostly to make basic horizontal
// listings of icons or texts.
.h-stack {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;

  > * {
    display: inline-block;
    vertical-align: middle;

    + * {
      margin-left: 10px;
    }
  }
}

.h-stack--top > *  { vertical-align: top; }
.h-stack--base > * { vertical-align: baseline; }
.h-stack--btm > *  { vertical-align: bottom; }
