/**
 *
 * Based on flexbox, and aligned to follow our naming conventions.
 *
 */

@import "variables";

// Containers

.grid {
  @include grid-container();
  // @include grid-container-breakpoints();
}

// .grid--full-width {
//   @include grid-container();
// }

// Rows

.row {
  @include grid-row();

  // TODO: fix
  // Remove the negative margin from default .row, then the horizontal padding
  // from all immediate children columns (to prevent runaway style inheritance).
  &.row--no-gutters {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;

    border-radius: 4px;

    > .column,
    > [class*="column--"] {
      padding-top: 0;
      padding-right: 0;
      padding-bottom: 0;
      padding-left: 0;
      margin-top: 0;
      margin-right: 0;
      margin-bottom: 0;
      margin-left: 0;
    }

    .row-glide {
      .column {
        padding-top: 0;
        padding-right: 0;
        padding-bottom: 0;
        padding-left: 0;
        margin-top: 0;
        margin-right: 0;
        margin-bottom: 0;
        margin-left: 0;
      }
    }

    + .row--gutters {
      padding-bottom: 0;
    }
  }

  &.row--gutters {
    & + & {
      padding-top: 0;
      padding-bottom: 0;

      &:last-of-type {
        padding-bottom: calc($grid-gutter-width / 4);
      }
    }

    &:first-of-type {
      padding-bottom: 0;
    }

    + .row--no-gutters {
      margin-top: calc($grid-gutter-width / 4);
    }

    .content-group,
    .content-group__background-image {
      border-radius: 4px;
    }
  }

  // Reset columns to work within the glide slides
  .row-glide {
    .column {
      flex: 1 0 auto;
      width: auto;
      max-width: none;
    }
  }
}

// remove left and right gutter for when a row is inside a container
.container {
  .row {
    margin-right: -15px;
    margin-left: -15px;

    &.row--no-gutters {
      margin-right: 0;
      margin-left: 0;
    }
  }
}

// Standard grid classes

%grid-column {
  @include grid-column-base();
}

@each $breakpoint in map-keys($grid-breakpoints) {
  $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
  $sep: if($infix == "", "--", "-");

  // Allow columns to stretch full width below their breakpoints
  @for $i from 1 through $grid-columns {
    .column#{$infix}#{$sep}#{$i} {
      @extend %grid-column;
    }
  }
  .column#{$infix},
  .column#{$infix}#{$sep}auto {
    @extend %grid-column;
  }

  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    // Provide basic `.col-{bp}` classes for equal-width flexbox columns
    .column#{$infix} {
      flex-grow: 1;
      max-width: 100%;
    }
    .column#{$infix}#{$sep}auto {
      flex: 1 0 auto;
      width: auto;
      max-width: none; // Reset earlier grid tiers
    }

    @for $i from 1 through $grid-columns {
      .column#{$infix}#{$sep}#{$i} {
        @include grid-column-size($i, $grid-columns);
      }
    }

    .order#{$infix}#{$sep}first {
      order: -1;
    }

    .order#{$infix}#{$sep}last {
      order: $grid-columns + 1;
    }

    @for $i from 0 through $grid-columns {
      .order#{$infix}#{$sep}#{$i} {
        order: $i;
      }
    }

    // `$grid-columns - 1` because offsetting by the width of an entire row isn't possible
    @for $i from 0 through ($grid-columns - 1) {
      @if not($infix == "" and $i == 0) {
        // Avoid emitting useless .offset-0
        .offset#{$infix}#{$sep}#{$i} {
          @include grid-column-offset($i, $grid-columns);
        }
      }
    }
  }
}
