
.catalog-pagination {
  list-style: none;
  padding: 0;
}

.catalog-pagination__item {
  display: inline-block;
  padding: 0 1px;
}

.catalog-pagination__link {
  padding: 10px 18px;
  display: inline-block;
  font-weight: 600;
  color: $gray-900;
  border-radius: 2px;

  svg {
    display: inline-block;
    position: relative;
    top: -1px;
    vertical-align: middle;
    width: 10px;
  }

  &:hover {
    text-decoration: none;
    background-color: $gray-200;
  }

  &.is-disabled {
    pointer-events: none;
    color: $gray-300;

    svg {
      fill: $gray-300;
    }
  }

  &.is-selected {
    pointer-events: none;
    color: $white;
    background-color: $black;
  }
}
