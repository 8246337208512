
.quickbuy {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  p {
    font-weight: 500;
  }
}

.quickbuy.is-error {
  .quickbuy__loading {
    display: none;
  }
  .quickbuy__success {
    display: none;
  }
  .quickbuy__error {
    display: block;
  }
}

.quickbuy.is-success {
  .quickbuy__loading {
    display: none;
  }
  .quickbuy__success {
    display: block;
  }
  .quickbuy__error {
    display: none;
  }
}


// Loading

.quickbuy__loading {
  width: 400px;
  text-align: center;
  color: $gray-700;
}

@keyframes quickbuy-spin {

  0%  {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.quickbuy__spinner {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-bottom: 10px;
  border: 3px solid $gray-700;
  border-right-color: transparent;
  border-radius: 50%;
  animation: quickbuy-spin .8s infinite linear;
}


// Success

.quickbuy__success {
  width: 400px;
  text-align: center;
  color: $green-700;
  display: none;

  a {
    color: $green-700;
    &:hover {
      color: $green-700;
    }
  }
}

.quickbuy__success-icon {
  display: inline-block;
  margin-bottom: 10px;
  opacity: 0.8;
  color: $green-700;

  svg {
    width: 4em;
    height: 4em;
    display: inline-block;
  }
}


// Error

.quickbuy__error {
  width: 400px;
  text-align: center;
  color: $red-700;
  display: none;

  a {
    color: $red-700;
    &:hover {
      color: $red-700;
    }
  }
}

.quickbuy__error-icon {
  display: inline-block;
  margin-bottom: 10px;
  opacity: 0.8;
  color: $red-700;

  svg {
    width: 4em;
    height: 4em;
    display: inline-block;
  }
}
