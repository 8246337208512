@import "variables";

@mixin button-color($primary, $secondary, $hover) {
  border-color: $primary;
  background: transparent;
  color: $primary;

  &:hover {
    background: $primary;
    color: $secondary;
  }

  &:focus {
    color: $primary;

    &:hover {
      color: $secondary;
    }
  }

  &.button--solid {
    border-color: $primary;
    background: $primary;
    color: $secondary;

    &:hover {
      border-color: $hover;
      background: $hover;
    }

    &:focus {
      color: $secondary;
    }
  }

  &.button--link {
    &:hover {
      background: transparent;
      color: $hover;
    }
  }

  &.button--icon,
  &.button--icon-large {
    &:hover {
      background: transparent;
      color: $hover;
    }
  }

  &.is-inactive {
    background-color: $gray-100;
    color: $gray-700;
    border-color: $gray-300;
  }

  &.is-loading {
    &::before {
      border-color: $primary;
      border-right-color: transparent;
    }

    &.button--solid {
      &::before {
        border-color: $secondary;
        border-right-color: transparent;
      }
    }
  }
}

@mixin button-orange() {
  @include button-color($orange-500, $white, $orange-400);
}

@mixin button-purple() {
  @include button-color($purple-500, $white, $purple-400);
}

@mixin button-white() {
  @include button-color($white, $gray-900, $gray-300);
}

@mixin button-black() {
  @include button-color($gray-900, $white, $gray-800);
}
