/**
 * @file
 * SCSS partial for compatibility component styles.
 */

.compatibility-section {
  padding: 2em 0 3em;
  background: $blue-500;

  .column {
    flex-basis: 50%;

    @include media-breakpoint-up(sm) {
      flex-basis: 0;
    }
  }
}

.compatibility-section--dark-embedded {
  position: relative;
  display: none;
  z-index: 50;
  padding: 3em 3em 2em;
  background: transparent;

  @include media-breakpoint-up(sm) {
    display: block;
    margin-top: -80px;
  }

  .compatibility-item {
    color: $white;

    svg {
      height: 2em;
      width: 2em;

      @include media-breakpoint-up(sm) {
        height: 3em;
        width: 3em;
      }
    }
  }
}

.compatibility-section--light-embedded {
  position: relative;
  padding: 2em 0 0 0;
  background: transparent;

  .compatibility-item {
    color: $gray-900;

    svg {
      height: 2em;
      width: 2em;
      color: $gray-900;

      @include media-breakpoint-up(sm) {
        height: 3em;
        width: 3em;
      }
    }
  }
}

.compatibility-item {
  min-height: 160px;
  margin-bottom: 2em;
  text-align: left;
  color: $white;

  @include media-breakpoint-up(sm) {
    min-height: auto;
    margin-bottom: 0;
  }

  svg {
    height: 7em;
    width: 7em;
  }

  figure {
    margin-left: 0;
  }
}

.compatibility-item__label {
  margin-top: 1em;

  p {
    margin-bottom: .8rem;
  }
}

.compatibility-item__details {
  color: $gray-700;
  text-align: left;
  font-size: 1.3rem;

  ul {
    list-style: outside none;
    margin: 0;
    padding: 0;
  }
}





