// <span class="spacer" data-axis="vertical" style="--size: 12px;"></span>

.spacer {
  &[data-axis="vertical"] {
    display: block;
    width: 1px;
    min-width: 1px;
    height: var(--size);
    min-height: var(--size);
  }

  &[data-axis="horizontal"] {
    display: inline-block;
    width: var(--size);
    min-width: var(--size);
    height: 1px;
    min-height: 1px;
  }
}
