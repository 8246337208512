.buy-section-price {
  display: none;

  &.is-visible {
    display: block;
  }
}

.buy-section-price-wrapper {
  display: inline-flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
}

.buy-section-price__with-bundle {
  display: inline-block;

  .with-bundle__message {
    font-weight: 600;
    color: $gray-600;
  }

  .buy-section-price__price,
  .buy-section-price__msrp {
    font-size: 2.8rem;
    font-weight: 600;
  }

  .buy-section-price__price {
    color: $orange-500;
  }

  .buy-section-price__msrp {
    color: $gray-700;
    margin-right: 5px;
    text-decoration: line-through;
  }
}

.buy-section-price__with-discount {
  display: flex;
  align-items: baseline;
  gap: 12px;

  .buy-section-price__price {
    color: $orange-500;
  }
}

.buy-section-price__price {
  font-size: 2.8rem;
  font-weight: 600;
}

.buy-section-label-msrp {
  color: $gray-600;
}

.buy-section-label-msrp {
  margin-right: 5px;
}

.buy-section-price__msrp {
  margin-right: 5px;
  color: $gray-600;
  text-decoration: line-through;
}

.buy-section-price__in-stock-label {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  font-size: 1.8rem;
  font-weight: 700;
  color: #008000;
}

.affirm-as-low-as {
  font-size: 1.6rem;
  text-transform: lowercase;
  margin: 10px 0;
  transition: opacity 200ms ease;
}

// Membership callout
.buy-section-price__membership {
  flex: 100%;
}

.buy-section-price__membership-title {
  font-weight: 600;
  color: $purple-500;
}

.buy-section-price__membership-body {
  color: $gray-700;
  margin-bottom: 0;

  strong {
    color: $purple-500;
  }
}

//omnibus
.buy-section-price-history {
  display: none;
  &.is-visible {
    display: block;
  }
}
