
.step-reviews {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 1.4rem;

  strong {
    font-weight: 500;
  }
}

.step-reviews__step {
  border: 1px solid $gray-300;
  border-radius: 0px;
  padding: 10px 15px;
  display: flex;

  & + & {
    border-top: 0;
  }

  &:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  &:last-child {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
}

.step-reviews__info {
  flex: 1;

  strong {
    display: block;

    @include media-breakpoint-up(sm) {
      display: inline-block;
      margin-right: 5px;
    }
  }

  small {
    color: $gray-700;
  }
}

.step-reviews__address--detail {
  display: block;
}

.step-reviews__link {
  text-align: right;
  color: $purple-500;

  &:hover {
    color: $purple-500;
    text-decoration: underline;
  }
}

.step-reviews__price {
  font-weight: 500;
  margin-right: 15px;
}
