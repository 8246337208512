/*
* Form actions (e.g. buttons)
*
*/

.form-actions {
  margin: 1.5em 0 0;
}


/* Variants */

.form-actions--right {
  text-align: right;
}

// spacing for form buttons in auth-views
.form-actions--auth {
  padding-top: 20px;
}
