.category-list {
  list-style: none;
  margin: 0;
  padding: 0 0 15px;
  border-bottom: 2px solid $gray-300;

  li {
    margin-bottom: 0.1em;
  }
}

.category-list__link {
  display: block;
  color: $gray-900;
  padding: 3px 0;

  &.is-selected {
    font-weight: bold;
  }
}

.category__title {
  margin: 10px 0;
  text-transform: uppercase;
  @include body-100;
  font-weight: bold;
}

.category-list-mobile {
  .category-list {
    max-height: 1000px;
    overflow: hidden;
    padding-left: 10px;
    margin-bottom: 10px;
    transition: max-height 300ms ease;
  }

  &.is-collapsed {
    .category-list {
      max-height: 0;
      margin-bottom: 0;
    }
  }
}
