@import "mixins/containers";

// Hide from screen readers and browsers
//
.hidden {
  display: none !important;
}

// Responsive utilities for large and xlarge viewports.
//
.container--flex {
  @include containerFlex;
}

// Regular Containers
//
.container {
  @include container;
}

// Full container with gutter on each side.
//
.container--full {
  @include containerFull;
}

.container--no-gutter {
  padding: 0;

  .column {
    padding: 0;
  }
}

// Basic themes when you need them
//
.theme-dark {
  background: $black;

  * {
    color: $white;
  }
}

.theme-light {
  background: $white;

  * {
    color: $gray-900;
  }
}

/* Omnibus */
.omnibus {
  color: #575757;
  padding:10px 0px;
}
.omnibus-link {
  position: relative;
  display: inline;
  white-space: nowrap;
}
.omnibus-link-label {
  text-decoration: underline;
  cursor: pointer;
  display:inline-block;
  padding-left: 5px;
}
.arrow-box {
  box-sizing: border-box;
  color: black;
  font-size: 12px;
  display: none;
  flex-direction: column;
  align-items: flex-end;
  padding: 16px 20px;
  gap: 50px;
  margin-bottom: 20px;
  margin-top: 10px;
  position: absolute;
  min-width: 300px;
  top: 100%;
  left: -10%;
  background: #FFFFFF;

  /* Gray/400 */
  border: 1.5px solid #BFBFBF;
  border-radius: 3px;
  @media only screen and (min-width: 985px) and (max-width: 1500px) {
    position: relative;
    left: 0;
  }
  @media only screen and (max-width: 985px) {
    position: relative;
    left: 0;
  }
}

.arrow-box:after,
.arrow-box:before {
  bottom: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow-box:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
  border-width: 9px;
  margin-left: -9px;
  left: 50%;
  @media only screen and (max-width: 600px){
    left: 60%;
    right: auto;
  }
}

.arrow-box:before {
  border-color: rgba(113, 158, 206, 0);
  border-bottom-color: #BFBFBF;
  border-width: 10px;
  margin-left: -10px;
  left: 50%;
  @media only screen and (max-width: 600px){
    left: 60%;
    right: auto;
  }
}

.close-arrow-box {
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 10px;
}


.arrow-box .title {
  font-weight: bold;
  font-size: 16px;
}
