/**
 *
 * Based on flexbox, and aligned to follow our naming conventions.
 *
 */
@import "variables";
@import "breakpoints";

// Mixins used for defining standard grid classes

@mixin grid-container() {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

// // For each breakpoint, define the maximum width of the container in a media query
// @mixin grid-container-breakpoints($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
//   @each $breakpoint, $container-max-width in $max-widths {
//     @include media-breakpoint-up($breakpoint, $breakpoints) {
//       max-width: $container-max-width;
//     }
//   }
// }

@mixin frontpage-grid-row() {
  display: flex;
  flex-wrap: wrap;
  margin-right: ($grid-gutter-width * -0.5);
  margin-left: ($grid-gutter-width * -0.5);
}

@mixin frontpage-grid-column-base() {
  position: relative;
  // Prevent columns from becoming too narrow when at smaller grid tiers by
  // always setting `width: 100%;`. This works because we use `flex` values
  // later on to override this initial width.
  width: 100%;
  min-height: 1px; // Prevent collapsing
  padding-right: ($grid-gutter-width * 0.5);
  padding-left: ($grid-gutter-width * 0.5);
}

@mixin grid-row() {
  display: flex;
  flex-wrap: wrap;
  padding: calc($grid-gutter-width / 4);
}

@mixin grid-column-base() {
  position: relative;
  // Prevent columns from becoming too narrow when at smaller grid tiers by
  // always setting `width: 100%;`. This works because we use `flex` values
  // later on to override this initial width.
  width: 100%;
  min-height: 1px; // Prevent collapsing
  margin-top: calc($grid-gutter-width / 4);
  padding-right: calc($grid-gutter-width / 4);
  margin-bottom: calc($grid-gutter-width / 4);
  padding-left: calc($grid-gutter-width / 4);
}

@mixin grid-column-size($size, $columns: $grid-columns) {
  flex: 0 0 percentage(calc($size / $columns));
  // Add a `max-width` to ensure content within each column does not blow out
  // the width of the column. Applies to IE10+ and Firefox. Chrome and Safari
  // do not appear to require this.
  max-width: percentage(calc($size / $columns));
}

@mixin grid-column($size, $columns: $grid-columns) {
  @include grid-column-base();
  @include grid-column-size($size, $columns);
}

@mixin grid-column-offset($size, $columns: $grid-columns) {
  $num: calc($size / $columns);
  margin-left: if($num == 0, 0, percentage($num));
}
