.tooltip {
  position: absolute;
  z-index: 100;
  color: $white;
  background-color: $gray-900;
  border-radius: 4px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);

  &:hover {
    display: none;
  }

  .tooltip__arrow {
    border-color: $gray-900;
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 0;

    &:hover {
      display: none;
    }
  }
}

.tooltip__inner {
  padding: 5px 10px;
  font-size: 80%;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  max-width: $tooltip-max-width;
}

.tooltip[x-placement^="top"] .tooltip__arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  bottom: -4px;
}

.tooltip[x-placement^="bottom"] .tooltip__arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
  top: -4px;
}

.tooltip[x-placement^="left"] .tooltip__arrow {
  border-width: 5px 0 5px 5px;
  border-right-color: transparent;
  border-top-color: transparent;
  border-bottom-color: transparent;
  right: -4px;
}

.tooltip[x-placement^="right"] .tooltip__arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent;
  border-top-color: transparent;
  border-bottom-color: transparent;
  left: -4px;
}

// tippy styles
.tippy-box {
  background-color: $white;

  button {
    cursor: pointer;
  }
}

.tippy-box[data-animation="fade"][data-state="hidden"] {
  opacity: 0;
}
[data-tippy-root] {
  max-width: calc(100vw - 10px);
}
.tippy-box {
  position: relative;
  background-color: $gray-900;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  color: $white;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.4;
  white-space: normal;
  outline: 0;
  transition-property: transform, visibility, opacity;
}
.tippy-box[data-placement^="top"] > .tippy-arrow {
  bottom: 0;
}
.tippy-box[data-placement^="top"] > .tippy-arrow:before {
  bottom: -7px;
  left: 0;
  border-width: 8px 8px 0;
  border-top-color: initial;
  transform-origin: center top;
}
.tippy-box[data-placement^="bottom"] > .tippy-arrow {
  top: 0;
}
.tippy-box[data-placement^="bottom"] > .tippy-arrow:before {
  top: -7px;
  left: 0;
  border-width: 0 8px 8px;
  border-bottom-color: initial;
  transform-origin: center bottom;
}
.tippy-box[data-placement^="left"] > .tippy-arrow {
  right: 0;
}
.tippy-box[data-placement^="left"] > .tippy-arrow:before {
  border-width: 8px 0 8px 8px;
  border-left-color: initial;
  right: -7px;
  transform-origin: center left;
}
.tippy-box[data-placement^="right"] > .tippy-arrow {
  left: 0;
}
.tippy-box[data-placement^="right"] > .tippy-arrow:before {
  left: -7px;
  border-width: 8px 8px 8px 0;
  border-right-color: initial;
  transform-origin: center right;
}
.tippy-box[data-inertia][data-state="visible"] {
  transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);
}
.tippy-arrow {
  width: 16px;
  height: 16px;
  color: $gray-900;
}
.tippy-arrow:before {
  content: "";
  position: absolute;
  border-color: transparent;
  border-style: solid;
}
.tippy-content {
  position: relative;
  padding: 5px 9px;
  z-index: 1;
  pointer-events: all;
}

// tippy animations
.tippy-box[data-animation="shift-away-subtle"][data-state="hidden"] {
  opacity: 0;

  // removes animation for users who prefer reduced motion
  @media (prefers-reduced-motion: reduce) {
    opacity: 1;
  }
}
.tippy-box[data-animation="shift-away-subtle"][data-state="hidden"][data-placement^="top"] {
  transform: translateY(5px);

  // removes animation for users who prefer reduced motion
  @media (prefers-reduced-motion: reduce) {
    transform: none;
  }
}
.tippy-box[data-animation="shift-away-subtle"][data-state="hidden"][data-placement^="bottom"] {
  transform: translateY(-5px);

  // removes animation for users who prefer reduced motion
  @media (prefers-reduced-motion: reduce) {
    transform: none;
  }
}
.tippy-box[data-animation="shift-away-subtle"][data-state="hidden"][data-placement^="left"] {
  transform: translateX(5px);

  // removes animation for users who prefer reduced motion
  @media (prefers-reduced-motion: reduce) {
    transform: none;
  }
}
.tippy-box[data-animation="shift-away-subtle"][data-state="hidden"][data-placement^="right"] {
  transform: translateX(-5px);

  // removes animation for users who prefer reduced motion
  @media (prefers-reduced-motion: reduce) {
    transform: none;
  }
}

// themes
.tippy-box[data-theme~="light"] {
  color: #26323d;
  background-color: $white;
}
.tippy-box[data-theme~="light"][data-placement^="top"] > .tippy-arrow:before {
  border-top-color: $white;
}
.tippy-box[data-theme~="light"][data-placement^="bottom"]
  > .tippy-arrow:before {
  border-bottom-color: $white;
}
.tippy-box[data-theme~="light"][data-placement^="left"] > .tippy-arrow:before {
  border-left-color: $white;
}
.tippy-box[data-theme~="light"][data-placement^="right"] > .tippy-arrow:before {
  border-right-color: $white;
}
.tippy-box[data-theme~="light"] > .tippy-backdrop {
  background-color: $white;
}
.tippy-box[data-theme~="light"] > .tippy-svg-arrow {
  fill: $white;
}

// hotspot
.tippy-box[data-theme~="hotspot"] {
  color: $gray-900;
  background-color: $white;
  border-radius: 0;
  padding: 26px;
}
.tippy-box[data-theme~="hotspot"][data-placement^="top"] > .tippy-arrow:before {
  border-top-color: $white;
}
.tippy-box[data-theme~="hotspot"][data-placement^="bottom"]
  > .tippy-arrow:before {
  border-bottom-color: $white;
}
.tippy-box[data-theme~="hotspot"][data-placement^="left"]
  > .tippy-arrow:before {
  border-left-color: $white;
}
.tippy-box[data-theme~="hotspot"][data-placement^="right"]
  > .tippy-arrow:before {
  border-right-color: $white;
}
.tippy-box[data-theme~="hotspot"] > .tippy-backdrop {
  background-color: $white;
}
.tippy-box[data-theme~="hotspot"] > .tippy-svg-arrow {
  fill: $white;
}
