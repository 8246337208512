// Import original selectize
@import "../selectize/selectize";

// Overwrite to match styling
.selectize-input {
  transition: border 0.25s ease;
  border: none;
  border-bottom: 1px solid $gray-500;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  padding-left: 0.2em;

  &:hover {
    border-color: $gray-900;
  }
}
.selectize-input.focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.selectize-input.dropdown-active {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.selectize-dropdown {
  transition: border 0.25s ease;
  border: 1px solid $gray-500;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;

  &:hover {
    border-color: $gray-900;
  }
}

.selectize-dropdown .active {
  background-color: $yellow-100;
  color: $gray-900;
}

.selectize-dropdown .optgroup-header {
  color: $gray-500;
}

.selectize-dropdown,
.selectize-input,
.selectize-input input {
  font-size: inherit;
}
