// codes
.dashboard-promo-codes {
  text-align: left;
  border-collapse: collapse;
  border: 1px solid $gray-300;

  th {
    background-color: $gray-200;
    font-weight: 400;
  }

  td,
  th {
    border: 1px solid $gray-300;
    padding: 15px 20px 13px;
  }
}
