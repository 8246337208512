/**
 * Form rows, containing multiple fields side-by-side
 */

.form-row {
  margin: 0 -5px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0; // use any real widths & divide up remaining space evenly

  .form-field {
    // Fields get padding on either side, matching the negative margin on the row
    padding-left: 5px;
    padding-right: 5px;
  }

  // Top margin on form fields in consecutive rows
  //  * Keeps rows without any fields at height 0
  //  * Avoids top/bottom margin on single rows
  //  * Adds spacing between consecutive rows
  & + & {
    .form-field,
    .form-field + .form-field {
      margin-top: .85em;

      @include media-breakpoint-up(sm) {
        margin-top: 1.25em;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    flex-direction: row;

    // No top margin on side-by-side form fields (overwrites default .form-field style)
    .form-field + .form-field {
      margin-top: 0;
    }
  }
}

.form-row {
  &.form-row--vert-sm {
    & + & {
      .form-field,
      .form-field + .form-field {
        margin-top: 0;

        @include media-breakpoint-up(sm) {
          margin-top: .25em;
        }
      }
    }
  }
}
