.buy-section-addons {
  display: none;

  &.is-visible {
    display: block;
  }
}

.buy-section-addon {
  --text-size: 1.6rem;
  --price-size: 1.4rem;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 25px;
  width: fit-content;
  background: $gray-100;
  padding: 1em;
}

.addon__checkbox {
  --size: 24px;

  & input[type="checkbox"] {
    width: var(--size);
    height: var(--size);
  }
}

.addon__content {
  font-size: var(--text-size);
  font-weight: 600;

  .addon__content-description {
    color: $gray-700;
  }
}

.addon__price {
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: bold;
  font-size: var(--price-size);

  .msrp {
    font-weight: 400;
    text-decoration: line-through;
    color: $gray-700;
  }

  .sale-price {
    font-weight: 600;
    color: $orange-500;
  }
}

.addon__image-link {
  width: 100px;
  height: 100px;

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
    background-color: white;
  }
}

.buy-section-reverse-addon {
  border-left: 7px solid $black;
  background-color: $gray-100;
  margin-top: 20px;
  padding: 15px 24px;
  font-weight: 700;

  a {
    margin-left: 8px;
    color: $black;
    font-weight: 400;
  }
}