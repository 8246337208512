/**
 * @file
 * SCSS partial for classes related to icons.
 */

.icon--orange { color: $orange-500; }
.icon--black { color: $black; }
.icon--white { color: $white; }

.icon--muted { opacity: .5; }

// Social icons
.icon-social {
  width: 35px;
  height: 35px;
  background: transparent none 0 0 no-repeat;
  display: inline-block;
  position: static;
}
// these icons don't exist
//.icon--twitter   { background-image: url(../img/icons/social-twitter.png); }
//.icon--instagram { background-image: url(../img/icons/social-instagram.png); }
//.icon--facebook  { background-image: url(../img/icons/social-facebook.png); }
//.icon--youtube   { background-image: url(../img/icons/social-youtube.png); }
//.icon--twitch    { background-image: url(../img/icons/social-twitch.png); }
