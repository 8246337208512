/*
 * Legal Disclaimer
 *
 * These Fonts are licensed only for use on these domains and their subdomains:
 * steelseries.com
 *
 * It is illegal to download or use them on other websites.
 *
 * While the @font-face statements below may be modified by the client, this
 * disclaimer may not be removed.
 *
 * Lineto.com, 2015
 */

@font-face {
  font-family: "Replica Pro";
  src: url("../fonts/replica/ReplicaPro-Bold.eot");
  src: url("../fonts/replica/ReplicaPro-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/replica/ReplicaPro-Bold.woff2") format("woff2"),
    url("../fonts/replica/ReplicaPro-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Replica Pro";
  src: url("../fonts/replica/ReplicaPro-Heavy.eot");
  src: url("../fonts/replica/ReplicaPro-Heavy.eot?#iefix") format("embedded-opentype"),
    url("../fonts/replica/ReplicaPro-Heavy.woff2") format("woff2"),
    url("../fonts/replica/ReplicaPro-Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
