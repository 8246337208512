// Cart page styles

@import "mixins/typography";

.container--cart-page {
  max-width: 1000px;
  padding: 15px 15px;

  @include media-breakpoint-up(sm) {
    padding: 50px 15px;
  }
}

// Cart Loading
.container--cart-page-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;

  .button {
    border: 0;
    padding: 0;

    &.is-loading::before {
      height: 5rem;
      width: 5rem;
    }
  }
}

.cart__main {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}

// Cart Empty
.cart-empty-button {
  width: 100%;

  @include media-breakpoint-up(sm) {
    width: 30% !important;
  }
}

// Cart Left

.cart-left {
  flex-basis: 100%;
  overflow-y: scroll;
  max-height: 350px;

  @include media-breakpoint-up(md) {
    margin-top: 1em;
    margin-right: 3em;
    flex-basis: 65%;
    overflow-y: hidden;
    max-height: none;
  }
}

.cart__header h1 {
  display: inline-block;
  margin-right: 5px;
  margin-top: 0;
}

.cart__items {
  .cart-widget__items-list {
    overflow-y: visible;
  }

  .cart-widget__section {
    padding: 0;
    border-bottom: 0;
  }

  table {
    width: 100%;
    border-spacing: 0;

    @include media-breakpoint-up(sm) {
      width: 95%;

      td {
        padding: 10px;
      }

      .widget-item__thumbnail {
        width: 150px;
        height: 120px;
      }
    }
  }
}

// Inventory Error Message

.main .checkout-v3-step__messages {
  margin-top: 1em;

  @include media-breakpoint-up(sm) {
    margin-bottom: -3em;
  }
}

// Cart Right

.cart-right-wrapper {
  background-color: $gray-100;
  padding: 1px 1em 1em 1em;
  margin: 1em 0;

  @include media-breakpoint-up(sm) {
    padding: 5px 2em 2em 2em;
  }

  .cart-widget__section {
    padding: 0;
    border-bottom: 0;
  }

  .cart-widget__footer {
    padding-top: 1em;
  }

  .widget-payment__icons {
    display: flex !important;

    img {
      margin: 0 0.4em;
    }

    .widget-payment__icon--paypal,
    .widget-payment__icon--affirm {
      height: 12px;
      align-self: center;

      @include media-breakpoint-up(sm) {
        height: 16px;
      }
    }

    .widget-payment__icon--paypal {
      margin-left: 0;
      margin-top: 0.25em;
    }
  }
}

.cart-totals-table {
  margin: 1em 0;
  border-collapse: collapse;

  th,
  td {
    font-weight: normal;
  }

  .cart-total--grand {
    th,
    td {
      padding: 1em 0;
      font-size: 1.8rem;
      border-top: 2px solid $gray-300;
    }

    .cart-total__amount span {
      font-weight: bold;
    }
  }
}

// Cart Coupon

.cart__coupon {
  padding: 1.1em 0;
  border-bottom: 2px solid $gray-300;
}

.cart-coupon-trigger {
  display: block;
  text-decoration: none;
  padding: 0.5em 0;
  margin-left: 5px;

  &.is-hidden {
    display: none;
  }
}

// Cart Coupon - Form

.cart-coupon-form {
  display: block;

  // desktop form
  &.is-hidden {
    display: none;
  }

  // mobile form children
  .form-field--coupon-code__input,
  .cart-coupon-title,
  .cart-coupon-form__button {
    &.is-hidden {
      display: none;
    }
  }
}

.cart-coupon-form__wrapper {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  flex: 1 0 0;
}

.cart-coupon-title {
  flex: 3 1 100%;
  align-self: stretch;
  padding-bottom: 0.5em;
  margin-left: 2px; // account for outline
}

.cart-coupon-form__label {
}

.cart-coupon-form__input {
  flex-grow: 2;
  margin: 0 5px;
  text-transform: uppercase;
}

.cart-coupon-form__error {
  width: 100%;
  color: $red-600;
  font-size: 1.4rem;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: max-height 0ms 0ms, opacity 400ms 0ms;

  &.active {
    margin-top: 6px;
    height: auto;
    max-height: 100%;
    opacity: 1;
    transition: max-height 0ms 0ms, opacity 600ms 0ms;
  }
}

// Cart Coupon - Code

.cart-coupon-code {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  flex-direction: row;
  flex: 1 0 0;
}

.cart-coupon-code__label {
}

.cart__coupon-remove-content {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  .coupon-remove-content__left {
    display: flex;
    flex-grow: 2;
    padding: 0.8em 0 0.5em;
  }

  svg {
    width: 1.5em;
    height: 1.5em;
    margin-right: 0.5em;
    vertical-align: middle;
  }

  .cart__coupon {
    padding: 0;
  }

  .cart-button__remove {
    float: right;
    font-size: 2rem;

    @include media-breakpoint-up(md) {
      font-size: 1.2rem;
    }
  }
}

.cart-gwp_promo_conflict__message {
  display: none;
  color: $gray-700;
  border: 1px solid $gray-400;
  border-radius: 3px;
  padding: 0.5em;
  margin-top: 5px;
}

.cart-gwp_promo_conflict__message-mobile {
  display: none;
  color: $gray-700;
  border: 1px solid $gray-400;
  border-radius: 3px;
  padding: 0.5em;
  margin-top: 5px;
}

.cart-gwp_promo_conflict__enable {
  display: block;
}

// Cart Totals

.cart-totals-table {
  width: 100%;
  padding-top: 1em;
  white-space: nowrap;

  td {
    padding: 0;

    @include media-breakpoint-up(md) {
      padding: 0.5em;
    }
  }

  tr:nth-last-child(2) {
    th,
    td {
      padding-bottom: 1.4em;
    }
  }
}

.cart-benefits {
  padding: 1em 0 0 0;
  list-style-type: none;
  font-size: smaller;
}

.cart-benefit__note--shipping-free {
  color: $green-600;
}

.cart-total__free {
  color: $green-600;
  font-weight: bold;
} 
