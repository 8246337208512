@import "variables";
@import "mixins/grid";
@import "mixins/typography";
@import "mixins/breakpoints";

.buy-section__section-header,
.buy-section__section-sub-header {
  font-family: $body-font-family;
  font-weight: 700;
  margin: 0;
}

.buy-section__section-header {
  padding: 6px 0;
}

.buy-section__section-sub-header {
  color: $gray-700;
}

.buy-section__tabs {
  color: $gray-900;
}

.buy-section__tab-list {
  display: flex;
}

.buy-section__tab-content {
  outline-offset: 8px;

  // removes outline on safari's weird focus state
  &:focus {
    outline: none;
  }
}

.buy-section__tab {
  flex: 1;
  padding: 12px 0;
  font-weight: 500;
}

.button--tab {
  background: transparent;
  border: none;
  border-bottom: 2px solid transparent;
}

.buy-section__tab[aria-selected="true"] {
  border-color: $orange-500;
}

.buy-section__tab[aria-selected="false"] {
  border-color: $gray-300;
}

.buy-section__shipping {
  color: $gray-700;
}

.buy-section__shipping--title {
  font-size: 16px;
  margin: 0;
}

// VALUE BUTTON
.buy-section-option__swatch-label {
  margin: 0;

  span {
    color: $yellow-800;
    text-transform: capitalize;

    &.is-selected {
      color: $black;
    }
  }

  strong {
    font-weight: 700;
  }
}

.buy-section-v2-option__values-list {
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(12vw, 1fr));
  gap: 16px;

  .buy-section-option--buttons & {
    --vw: 48vw;
    grid-template-columns: repeat(auto-fit, minmax(var(--vw), 1fr));

    @include media-breakpoint-up(sm) {
      --vw: 32vw;
    }

    @include media-breakpoint-up(lg) {
      --vw: 12vw;
    }
  }

  .buy-section-option--swatches & {
    grid-template-columns: repeat(auto-fit, minmax(1vw, 40px));
    row-gap: 16px;
    column-gap: 8px;
    // added padding for new outline styles
    padding: 6px;
  }
}

.buy-section-v2__value {
  height: 43px;

  &.tooltip-active {
    height: calc(var(--height) + 52px);
  }
}

.buy-section-option__value__info-tooltip-container {
  display: block;
  position: fixed;
  top: var(--link-bottom);
  left: 0;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  transform: translateY(-20px);
  transition: all 200ms ease-in-out;
  transition-property: opacity, transform;

  &.is-active {
    position: absolute;
    height: var(--height);
    visibility: visible;
    opacity: 1;
    transform: translateY(0px);
  }
}

.buy-section-option__value__info-tooltip {
  --carrot-size: 1em;
  --border-size: 2px;

  position: relative;
  border-radius: 2px;
  padding: 1em;
  margin: var(--border-size);
  margin-bottom: var(--carrot-size);
  background-color: $white;
  box-shadow: 0 0 0 var(--border-size) $gray-300;

  &:before {
    content: "";
    position: absolute;
    top: -9px;
    left: var(--cursor-left);
    right: var(--cursor-right);
    width: var(--carrot-size);
    height: var(--carrot-size);
    transform: rotate(45deg);
    background-color: $white;
    border-top: var(--border-size) solid $gray-300;
    border-left: var(--border-size) solid $gray-300;
    border-radius: 1px;
    visibility: var(--caret-visibility);
  }
}

.value__info-tooltip__header {
  display: flex;
}

.value__info-tooltip__header-content {
  margin-right: auto;
}

.value__info-tooltip__close {
  display: inline-block;
  color: $gray-700;
}

/* ACCORDION STYLES */
.buy-section--v2 {
  .accordion-toggle__wrapper {
    display: flex;
  }

  .buy-section__accordion-toggle {
    display: flex;
    flex: 1;
    justify-content: space-between;

    // remove button styles
    background: none;
    border: none;
    padding: 0;

    // font
    font-weight: 700;
    line-height: 1.2;
    color: $gray-900;
  }

  .accordion.is-active .accordion__content {
    max-height: 1600px;
  }

  .accordion.is-active .accordion__toggle__collapse-arrow {
    transform: rotate(180deg);
  }

  .accordion__toggle__collapse-arrow {
    transform: rotate(0deg);
    transition: transform 100ms ease;
    color: $gray-700;
  }

  .accordion__content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 300ms ease;
  }
}

.buy-section-bundle__item-wrapper {
  .accordion-toggle__wrapper {
    display: flex;
    background-color: $gray-100;
    border-radius: 2px;
    color: $gray-900;
    padding: 0;
    transition: background-color 300ms ease;

    &:hover {
      background-color: $gray-300;
    }

    button {
      padding: .75em 1em;
      color: $gray-900;
    }
  }

  .accordion.is-active .accordion-toggle__wrapper {
    background-color: $gray-900;
    color: $white;

    button {
      color: $white;
    }
  }

  .buy-section__accordion-toggle {
    display: flex;
    flex: 1;
    justify-content: space-between;

    // remove button styles
    background: none;
    border: none;
    padding: 0;

    // font
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1.2;
  }

  .accordion__content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 300ms ease;
  }

  .accordion__content_inner {
    padding: 1em;

    ul {
      padding-left: 15px;
    }

    .button {
      padding: 10px 28px;
      font-size: 1.4rem;
    }
  }

  .accordion.is-active .accordion__content {
    max-height: 1600px;
  }

  .accordion__toggle__collapse-icons {
    display: flex;
    align-items: center;

    .collapse__plus {
      display: inline-flex;
    }

    .collapse__minus {
      display: none;
    }
  }

  .accordion.is-active .accordion__toggle__collapse-icons {
    .collapse__plus {
      display: none;
    }

    .collapse__minus {
      display: inline-flex;
    }
  }
}

.buy-section-option__wrapper {
  border-bottom: 2px solid $gray-300;
}

.buy-section-description__wrapper {
  border-top: 2px solid $gray-300;
}

.buy-section-option__reset {
  .button {
    font-size: 1.6rem;
    font-weight: 700;
    padding: 1rem;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    svg {
      width: 1em;
      height: 1em;
    }
  }
}

.media-set__image-v2 {
  img {
    display: block;
    height: auto;
    width: 100%;
    object-fit: contain;
  }
}

.three-dimensional-container {
  background-color: $gray-200;

  // TODO: I dont like this use of svg
  // background-image: url("../img/three-dimensional-assets/aerox-3/360-model.svg");
  // background-size: 20%;
  // background-repeat: no-repeat;
  // background-position: 50% 95%;
}

.three-dimensional-canvas {
  aspect-ratio: 16 / 10;
  width: 100%;
}
