// Shipping options

.shipping-option {
  .choice-box__label {
    display: flex;
  }
}

.shipping-option__widget {
  padding-right: 12px;

  input[type="radio"] {
    // TODO: this is some annoying base styles we have to overwrite
    vertical-align: top;
    margin: 3px 0 0 0;
  }

  table {
    width: 100%;
  }
}

.shipping-option__info {
  width: 100%;

  .shipping-option__top {
    display: flex;
  }
}

.shipping-option__header {
  flex: 2;
  font-size: 1.6rem;
  margin: 0;
}

.shipping-option__price {
  padding-left: 10px;
  font-weight: 500;
  text-align: right;
  vertical-align: top;
}

.shipping-option__details {
  small {
    display: inline-block;
    //color: $gray-500;
    margin-top: 5px;
    //font-size: 1.4rem;

    @include media-breakpoint-up(sm) {
      display: inline-block;
      margin-top: 0;
      // margin-left: 5px;
    }
  }
}


// States

.shipping-option.is-active {}
