
.catalog-breadcrumbs {
  font-weight: bold;
}

.catalog-breadcrumbs__item {
  color: $gray-900;

  &.is-current {
    pointer-events: none;
    text-decoration: none;
  }

  &:hover {
    color: $gray-900;
  }
}

.catalog-breadcrumbs__divider {
  display: inline-block;
  padding: 0 5px;
  font-weight: 400;
}
