@import "variables";
@import "mixins/grid";
@import "mixins/breakpoints";

$media-set-gutter-width: 8px;

.media-set {
  display: none;
  width: 100%;
  padding: 0;

  &.is-visible {
    display: block;
  }

  .buy-section__media-carousel & {
    position: absolute;
    visibility: hidden;
    display: block;
    height: 0;
    opacity: 0;

    &.is-visible {
      position: revert;
      visibility: visible;
      display: block;
      height: initial;
      opacity: initial;
    }

    @include media-breakpoint-up(sm) {
      transition: top 300ms ease;
    }

    @include media-breakpoint-up(md) {
      &.is-visible {
        top: 84px;
        position: sticky;
      }
    }

    --transparent-background-color: rgba(0, 0, 0, 0.6);

    .glide__bullets {
      background: var(--transparent-background-color);
      border-radius: 25px;
      padding: 6px 2px;

      @include media-breakpoint-up(sm) {
        padding: 12px 4px;
      }
    }

    .glide__bullet {
      background-color: transparent;
      border: 4px solid white;
      width: 10px;
      height: 10px;
      margin: 0 0.25em;

      &:focus {
        background-color: white;
        border-color: $purple-400;
      }

      &.glide__bullet--active {
        background-color: white;
        outline: 2px white solid;
        outline-offset: 2px;

        &:focus {
          outline-color: $purple-400;
          border-color: white;
        }
      }

      @include media-breakpoint-up(sm) {
        margin: 0 0.5em;

        &.glide__bullet--active {
          outline-width: 4px;
          outline-offset: 4px;
        }
      }
    }

    .glide__arrows {
      display: none;

      @include media-breakpoint-up(md) {
        display: revert;
      }
    }

    .glide__arrow {
      background-color: var(--transparent-background-color);
      padding: 12px;
      max-height: 90%;
      border-radius: 50px;
      transition: height 200ms ease;
      will-change: height;

      border: none;
      box-shadow: none;
      text-shadow: none;

      height: 48px;

      &:hover {
        height: 150px;
      }

      &:focus {
        outline: 3px solid $purple-500;
      }

      &:active {
        color: $gray-400;
      }

      &.glide__arrow--left {
        left: 14px;

        @include media-breakpoint-up(lg) {
          left: 24px;
        }
      }

      &.glide__arrow--right {
        right: 14px;

        @include media-breakpoint-up(lg) {
          right: 24px;
        }
      }

      &.glide__arrow--left svg {
        left: -2px;
      }

      &.glide__arrow--right svg {
        right: -2px;
      }

      svg {
        position: relative;
        width: 24px;
        height: 24px;
      }
    }

    @media (prefers-reduced-motion) {
      .glide__arrow {
        height: auto;
        transition: none;

        &:hover {
          height: auto;
        }
      }
    }
  }
}

.media-set__row {
  display: flex;
  flex-wrap: wrap;
  margin-left: ($media-set-gutter-width * -0.5);
  margin-right: ($media-set-gutter-width * -0.5);
  margin-bottom: $media-set-gutter-width;

  &+&:last-child {
    margin-bottom: 0;
  }
}

.media-set__media {
  position: relative;
  width: 100%;
  padding-left: ($media-set-gutter-width * 0.5);
  padding-right: ($media-set-gutter-width * 0.5);
  flex-grow: 0;
  flex-shrink: 0;

  &.media-set__media--full {
    --margin-size: 12px;

    @include media-breakpoint-up(lg) {
      --margin-size: 16px;
    }

    @include media-breakpoint-up(xl) {
      --margin-size: 32px;
    }

    flex-basis: 100%;

    .badge-background,
    .badge-image {
      width: 180px;
      height: 180px;

      @include media-breakpoint-up(xl) {
        width: 220px;
        height: 220px;
      }
    }

    .badge-content {
      gap: 4px;

      @include media-breakpoint-up(xl) {
        gap: 6px;
      }
    }
  }

  &.media-set__media--half {
    --margin-size: 6px;

    @include media-breakpoint-up(lg) {
      --margin-size: 8px;
    }

    @include media-breakpoint-up(xl) {
      --margin-size: 12px;
    }

    flex-basis: 50%;

    .badge-background {
      width: 140px;
      height: 140px;

      @include media-breakpoint-up(xl) {
        width: 180px;
        height: 180px;
      }
    }

    .badge-content {
      transform: scale(0.8);
      gap: 3px;

      @include media-breakpoint-up(xl) {
        transform: scale(1);
        gap: 4px;
      }
    }
  }

  .buy-section__media-carousel & {
    aspect-ratio: 16 / 10;
    padding: 0;
  }
}

.media-set__link {
  position: relative;
  display: flex;
  align-items: center;
  cursor: zoom-in;
  width: 100%;
  height: 100%;
  background: $gray-200;
  border: 2px solid $white;
  border-radius: 2px;

  &:hover {
    border-color: $black;
  }

  .buy-section__media-carousel & {
    cursor: pointer;
    border: none;
    border-radius: 0;

    &:hover {
      border: none;
    }
  }
}

.media-set__image {
  display: block;
  height: auto;
  width: 100%;
  object-fit: contain;
}

.media-set__badges {
  $multiplier: 16;
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  transition: opacity 500ms ease-out;

  .media-set__media--half & {
    $multiplier: 20;
  }

  &[data-align^="top-"] {
    top: var(--margin-size);

    .media-set__badge {
      top: 0;
    }

    &[data-axis="vertical"] {
      $base-increment: -5%;

      @include media-breakpoint-down(lg) {
        @for $i from 2 through 4 {
          & .media-set__badge:nth-child(4n + #{$i}) {
            $new-increment: $base-increment - (($i - 1) * $multiplier);
            transform: translateY($new-increment);
          }
        }
      }
    }
  }

  &[data-align^="bottom-"] {
    bottom: var(--margin-size);

    .media-set__badge {
      bottom: 0;
    }

    &[data-axis="vertical"] {
      $base-increment: 5%;

      @include media-breakpoint-down(lg) {
        @for $i from 2 through 4 {
          & .media-set__badge:nth-child(4n + #{$i}) {
            $new-increment: $base-increment +(($i - 1) * $multiplier);
            transform: translateY($new-increment);
          }
        }
      }
    }
  }

  &[data-align$="-left"] {
    left: var(--margin-size);

    .media-set__badge {
      left: 0;
    }

    &[data-axis="horizontal"] {
      $base-increment: -5%;

      @include media-breakpoint-down(lg) {
        @for $i from 2 through 4 {
          & .media-set__badge:nth-child(4n + #{$i}) {
            $new-increment: $base-increment - (($i - 1) * $multiplier);
            transform: translateX($new-increment);
          }
        }
      }
    }
  }

  &[data-align$="-right"] {
    right: var(--margin-size);

    .media-set__badge {
      right: 0;
    }

    &[data-axis="horizontal"] {
      $base-increment: 5%;

      @include media-breakpoint-down(lg) {
        @for $i from 2 through 4 {
          & .media-set__badge:nth-child(4n + #{$i}) {
            $new-increment: $base-increment +(($i - 1) * $multiplier);
            transform: translateX($new-increment);
          }
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.media-set__badge {
  position: absolute;
  transition-property: top, left, bottom, right, transform;
  transition-duration: 500ms;
  transition-timing-function: ease-out;
}

.badge-image {}

.badge-background {
  border-radius: 50%;
  background-color: $orange-500;
  color: $black;
  text-decoration: none;
  text-align: center;
  word-wrap: normal;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;

  &.badge--gold {
    /* badge_bg_gold */
    background: radial-gradient(49.71% 49.71% at 50.29% 49.71%, #F8EF92 0%, rgba(248, 239, 146, 0) 100%), radial-gradient(35.26% 35.26% at 85.55% 84.97%, #F8EF92 0%, rgba(248, 239, 146, 0) 100%), radial-gradient(35.26% 35.26% at 15.03% 14.45%, #F8EF92 0%, rgba(248, 239, 146, 0) 100%), radial-gradient(70.23% 70.23% at 85.84% 14.16%, #BF8E32 0%, rgba(191, 142, 50, 0) 100%), radial-gradient(70.23% 70.23% at 14.74% 85.26%, #BF8E32 0%, rgba(191, 142, 50, 0) 100%), #F8EF92;
  }

  &.badge--silver {
    /* badge_bg_holographic */
    background: radial-gradient(49.71% 49.71% at 50.29% 49.71%, #F2EBEB 0%, rgba(242, 235, 235, 0) 100%), radial-gradient(35.26% 35.26% at 85.55% 84.97%, #F2EBEB 0%, rgba(242, 235, 235, 0) 100%), radial-gradient(35.26% 35.26% at 15.03% 14.45%, #F2EBEB 0%, rgba(242, 235, 235, 0) 100%), radial-gradient(70.23% 70.23% at 85.84% 14.16%, #B3B3B3 0%, rgba(179, 179, 179, 0) 100%), radial-gradient(50.29% 50.29% at 0% 50.29%, #A1BCC4 0%, rgba(161, 188, 196, 0) 100%), radial-gradient(38.73% 38.73% at 11.56% 79.77%, #B693D2 0%, rgba(182, 147, 210, 0) 100%), radial-gradient(39.31% 39.31% at 21.39% 89.6%, #DF8686 0%, rgba(223, 134, 134, 0) 100%), radial-gradient(49.71% 49.71% at 50.29% 100%, #ED9578 0%, rgba(237, 149, 120, 0) 100%), #F2EBEB;
  }
}

.badge-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-family: $headings-font-family;

  svg {
    width: 100%;
    height: 100%;
  }

  * {
    display: block;
    transform: translate3d(0px, 0px, 0px);
    line-height: 0.9;
  }

  .badge__top {
    align-items: flex-end;
    margin-top: 8px;
  }

  .badge__middle {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
    margin: 10px 0;
    height: 48px;
    padding-left: 10px;
    padding-right: 10px;

    @include media-breakpoint-up(xl) {
      height: 64px;
      padding-left: 14px;
      padding-right: 14px;
    }

    .media-set__media--half & {
      height: 24px;

      @include media-breakpoint-up(xl) {
        height: 32px;
      }
    }

    * {
      margin: 0;
    }

    .badge__middle--large {
      font-size: clamp(4.4rem, 0.75vw + 1rem, 8.0rem);
    }

    .badge__middle-text {
      font-size: clamp(1.8rem, 0.6vw + 1rem, 2.4rem);
    }
  }

  .badge__bottom {
    align-items: flex-start;
    margin-bottom: 8px;
  }

  .badge__top,
  .badge__bottom {
    display: inline-flex;
    height: 32px;
    max-width: 80%;
    padding-left: 20px;
    padding-right: 20px;
    font-size: clamp(1.2rem, 0.25vw + 1rem, 1.6rem);
  }

  .badge__border {
    height: 1px;
    width: 66%;
    background-color: $black;

    &.border-transparent {
      background-color: transparent;
    }
  }

  .badge__spacer {
    height: 32px;
    width: 100%;
  }
}