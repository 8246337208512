.notify-me-modal__header {
  display: flex;
  align-items: center;
  margin: -32px -32px 0;
  padding: 16px 18px;
  border-bottom: 2px solid $gray-300;
}

.notify-me-modal__close-button {
  display: flex;
  color: $gray-700;
  margin-left: auto;

  svg {
    width: 1.4em;
    height: 1.4em;
  }
}

.notify-me__sku-list {
  padding-left: 18px;
}

.notify-me__sku-list-item {
  font-weight: bold;
}

.notify-me__sku-remove {
  display: inline-block;
  font-size: 1.4rem;
  margin-left: 6px;
}
