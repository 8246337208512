// Type Styles
// use `@include heading-x;` to output styles in selector


/*
  Heading styles
*/

@mixin heading-giga {
  // 3.6rem is as big as we can go on mobile
  font-size: 3.6rem;
  font-weight: 600;
  font-family: $headings-font-family;
  line-height: 1.2;
  margin-top: 20px;
  margin-bottom: 10px;

  @include media-breakpoint-up(sm) { font-size: 4.4rem; }
  @include media-breakpoint-up(md) { font-size: 6.3rem; }
  @include media-breakpoint-up(lg) { font-size: 7.6rem; }
}

@mixin heading-mega {
  font-size: 3.6rem;
  font-weight: 600;
  font-family: $headings-font-family;
  line-height: 1.2;
  margin-top: 20px;
  margin-bottom: 10px;

  @include media-breakpoint-up(sm) { font-size: 4.4rem; }
  @include media-breakpoint-up(md) { font-size: 5.4rem; }
}

// typography-alpha
@mixin heading-100 {
  font-size: 3.6rem;
  font-weight: 600;
  font-family: $headings-font-family;
  line-height: 1.2;
  margin-top: 20px;
  margin-bottom: 10px;

  @include media-breakpoint-up(lg) { font-size: 4.4rem; }
}

// typography-beta
@mixin heading-200 {
  font-size: 2.8rem;
  font-weight: 600;
  font-family: $headings-font-family;
  line-height: 1.2;
  margin-top: 20px;
  margin-bottom: 10px;

  @include media-breakpoint-up(lg) { font-size: 3.6rem; }
}

// typography-gamma
@mixin heading-300 {
  font-size: 2.4rem;
  font-weight: 600;
  font-family: $headings-font-family;
  line-height: 1.2;
  margin-top: 20px;
  margin-bottom: 10px;

  @include media-breakpoint-up(lg) { font-size: 2.8rem; }
}

// typography-delta
@mixin heading-400 {
  font-size: 2.4rem;
  font-weight: 400;
  font-family: $body-font-family;
  line-height: 1.2;
  margin-top: 10px;
  margin-bottom: 10px;
}

// typography-omega
@mixin heading-500 {
  font-size: 2rem;
  font-weight: 400;
  font-family: $body-font-family;
  line-height: 1.2;
  margin-top: 10px;
  margin-bottom: 10px;
}

// typography-zeta
@mixin heading-600 {
  font-size: 1.8rem;
  font-weight: 400;
  font-family: $body-font-family;
  line-height: 1.2;
  margin-top: 10px;
  margin-bottom: 10px;
}

/*
  Body styles
*/

// typography-theta
@mixin body-100 {
  font-size: 1.6rem;
  font-weight: 400;
  font-family: $body-font-family;
}
