
$choice-box-active-color: $blue-400;
$choice-box-border-radius: 2px;
$choice-box-border-color: $gray-300;

.choice-box {}

.choice-box__choice {
  position: relative;
  border: 1px solid $choice-box-border-color;
  border-radius: $choice-box-border-radius;
  padding: 1px; // To account for box inset shadow
  margin: 0;
  transition:
    border-color 100ms ease-in-out,
    box-shadow 100ms ease-in-out;

  &:hover {
    border-color: $choice-box-active-color;
    z-index: 1;
  }

  & + & {
    margin-top: 10px; // Adjust position to avoid border overlaps
  }
}

.choice-box__label {
  display: block;
  padding: 10px 15px;
  margin: 0;
  width: 100%;
  cursor: pointer;

  @include media-breakpoint-up(sm) {
    padding: 12px 15px;
  }
}

input.choice-box__input {
  // input selector needed to overwrite other forms styles
  // TODO: remove input selector
  vertical-align: top;
  margin: 3px 12px 0 0;
}

.choice-box__extra {
  padding: 0 15px;
  margin: 0;
  background: rgba($white, 0.8);
  height: auto;
  max-height: 0;
  overflow: hidden;
  transition:
    max-height 150ms ease-in-out,
    padding 150ms ease-in-out;
}


// States

.choice-box__choice.is-active {
  z-index: 1;
  border-color: $choice-box-active-color;
  box-shadow: inset 0 0 0 1px $choice-box-active-color;
  background: $blue-100;

  .choice-box__extra {
    // Very high max-height here, ideally it should be adjusted in
    // specific implementations
    max-height: 1000px;
    padding-top: 15px;
    padding-bottom: 15px;

    &:empty {
      padding: 0;
      max-height: 0;
    }
  }
}


// Variants

.choice-box--stacked {
  // Connects the boxes, so that there are no vertical spacing
  // between them. Only the top and bottom boxes get radius.

  .choice-box__choice {
    border-radius: 0;

    &:first-child {
      border-top-right-radius: $choice-box-border-radius;
      border-top-left-radius: $choice-box-border-radius;
    }

    &:last-child {
      border-bottom-right-radius: $choice-box-border-radius;
      border-bottom-left-radius: $choice-box-border-radius;
    }
  }

  .choice-box__choice + .choice-box__choice {
    margin-top: -1px;
  }
}


.choice-box--subtle {
  // Makes the selected state a lot subtler, by not adding
  // a thick border around it. This is useful when you're dealing
  // with a choice box that has nested forms, as the double
  // focus style looks out of place.

  .choice-box__choice {
    padding: 0;

    &:hover {
      border-color: $choice-box-border-color;
      z-index: 1;
    }

    &.is-active {
      border-color: $choice-box-border-color;
      box-shadow: none;
    }
  }
}
