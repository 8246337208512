
.applied-facet {
  position: relative;
  border: 2px solid $gray-300;
  border-radius: 4px;
  padding: 0.5em;
  margin-bottom: 0.5em;

  @include media-breakpoint-up(md) {
    margin-right: 0.5em;
  }
}

.applied-facet__title {
  display: block;
  font-size: 1.4rem;

  @include media-breakpoint-up(sm) {
    display: inline-block;
    font-size: inherit;
    margin-right: 0.5em;
  }
}

.applied-facet__clear {
  margin-bottom: 0.5em;

  @include media-breakpoint-up(md) {
    margin-right: 0.5em;
    order: 2;
  }
}

.applied-facet__constraint {
  display: inline-block;
  position: relative;
  padding-right: 0.5em;
  color: $gray-900;
  font-weight: bold;
  text-decoration: none;

  span {
    display: inline-block;
    position: relative;
    width: 16px;
    height: 16px;
    background: $white;
    border-radius: 50%;
    margin-left: 2px;
    vertical-align: text-bottom;
  }

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 14px;
  }

  &:hover {
    color: $gray-900;
    text-decoration: underline;

    span {
      background: $gray-900;
    }

    svg {
      fill: $white;
    }
  }
}
