/*
* CSS file specifically for A/B test related styles
*/

@import "variables";
@import "mixins/breakpoints";

// AB Test - AB GG Nav Update
.ab-gg-nav-variant {
  display: none;
}

.ab-gg-nav-update {
  .ab-gg-nav-control {
    display: none !important;
  }

  .ab-gg-nav-variant {
    display: block;
  }

  // Microsite
  .microsite-navigation {
    display: none;
    justify-content: flex-end;
    background-color: $black;
    border-bottom: 2px solid $gray-800;

    a {
      padding: 5px 15px;
      text-transform: capitalize;
    }

    @include media-breakpoint-up(lg) {
      display: flex;
    }
  }

  .main-navigation {
    position: relative;
    background-color: $black;
  }

  // Logo
  .navigation-container.is-sticky {
    .navigation-logo {
      padding: 0;
      margin-left: 0;

      svg {
        height: 24px;

        @include media-breakpoint-up(md) {
          height: 33px;
        }
      }
    }

    .navigation-logo__text {
      position: static;
      will-change: auto;
      opacity: 1;
    }
  }

  .navigation-branding {
    align-self: center;
    position: relative;
    z-index: 1101;
  }

  .navigation-logo {
    margin-left: 0;
  }

  // Category - Triggers
  .category-navigation__triggers {
    display: none;
    order: 2;
    width: unset;
    margin-left: 2em;

    ul {
      margin: 0;
      padding: 0;
    }

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }

  .category-navigation__item {
    transition-property: background-color;
    transition-duration: 150ms;
    transition-timing-function: ease-in-out;

    svg {
      transition-property: transform;
      transition-duration: 150ms;
      transition-timing-function: ease-in-out;
    }

    .category-navigation__item-trigger {
      background: none;
      border: 0;
      color: $white;
      padding: 1.5em 1.5em;
      min-height: 66px;

      outline-offset: -3px;

      svg {
        transform: rotate(0deg);
      }
    }

    &.is-active {
      background-color: $gray-800;

      svg {
        transform: rotate(180deg);
      }
    }
  }

  // Category - Nav (Headsets, etc.)
  .category-navigation {
    display: none;
    // margin: -3px 0 0;
    margin: 0;
    padding: 0 2em 0 0.75em;
    background-color: $gray-800;

    a {
      font-weight: normal;
      line-height: 55px;
      padding: 0 1.25em;
    }

    @include media-breakpoint-up(lg) {
      display: block;
      max-height: 0;
      opacity: 0;
      transition-property: max-height, opacity;
      transition-duration: 300ms;
      transition-delay: 150ms;
      transition-timing-function: ease-in-out;

      &.is-expanded {
        max-height: 200px;
        opacity: 1;
      }
    }
  }

  .category-navigation__list {
    display: none;
    align-items: center;
    justify-content: space-between;
    // margin-top: -2px;

    @include media-breakpoint-up(lg) {
      &.is-active {
        display: flex;
      }
    }
  }

  .category-navigation__list--products li {
    &:last-of-type {
      .category-navigation__item--sale {
        color: $orange-500;
        font-weight: bold;
      }
    }
  }

  // Category Nav - Download Button
  .category-navigation__download-button {
    a {
      display: block;
      padding: 0.4em 1em;
      font-size: 1.75rem;
      font-weight: bold;
      line-height: 1.25;

      &.is-hidden {
        display: none;
      }
    }
  }

  // Category - Nav Dropdowns
  .category-navigation__item.navigation-dropdown {
    cursor: pointer;

    &:hover {
      .navigation-dropdown__trigger {
        box-shadow: 0 -2px 0 0 $white inset;
      }
    }

    &>.navigation-dropdown__button {
      font-weight: 400;
      line-height: 55px;
      text-decoration: none;
      display: inline-block;
      padding: 0 1.25em;
      color: $white;
      background-color: transparent;
      border: none;

      &:focus {
        outline-offset: -8px;
      }
    }
  }

  .category-navigation__sub-list {
    background-color: $gray-700;

    li:last-of-type {
      border-top: 1px solid $gray-500;
    }

    a {
      padding: 1em;
      line-height: 1.5;
    }
  }

  // Courtesy
  .courtesy-navigation {
    order: 3;
  }

  .ab-courtesy-navigation__text {
    margin-right: 8px;
    font-size: 1.2rem;
    font-weight: 600;
    text-transform: uppercase;

    @include media-breakpoint-up(lg) {
      display: inline;
    }
  }

  // Mobile
  .navigation-container.is-mobile-expanded {
    .js-navigation-menu-mobile {
      overflow-y: auto;
      height: calc(100% + 1px);
    }

    .courtesy-navigation__menu-toggle {
      position: fixed;
      top: 0;
    }

    .navigation-branding {
      position: fixed;
      top: 0.5em;
      left: 3.1em;
    }

    .courtesy-navigation {
      position: fixed;
      top: 1px;
      right: 0;
    }
  }
}

.ab-courtesy-navigation__text {
  display: none;
}
