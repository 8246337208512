.buy-section-form {
  display: none;

  margin-top: 1.5em;

  &.is-visible {
    display: block;
  }

  &[data-form-type="add-to-cart"] {
    & [data-form] {
      display: none;
    }
    & [data-form="add-to-cart"] {
      display: block;
    }
  }

  &[data-form-type="sign-in-to-buy"] {
    & [data-form] {
      display: none;
    }
    & [data-form="sign-in-to-buy"] {
      display: block;
    }
  }

  &[data-form-type="notify-me"],
  &[data-form-type="sign-in-to-notify"] {
    & [data-form] {
      display: none;
    }
    & [data-form="notify-me"] {
      display: block;
    }
  }

  &[data-form-type="out-of-stock"] {
    & [data-form] {
      display: none;
    }
    & [data-form="out-of-stock"] {
      display: block;
    }
  }
}

//CRO-test [DPT-001]
div.notify-me__parent > div:nth-of-type(3) {
  display: none;
}

.optimize-usp-banner {
  list-style: none;
  padding: 0;
  margin: 0 1rem 2rem;
  @include media-breakpoint-up(sm) {
    display: none;
  }
}

.notify-me__description {
  background: $gray-200;
  padding: 1em;
  color: $gray-800;

  border-left: 0.5em solid $gray-400;
  p {
    margin-bottom: 0px;
  }
}

.notify-me__form {
  margin-top: 1em;
  border: 2px solid $gray-200;
  padding: 1em;

  .notify-me__form__label {
    color: $gray-700;
  }
  .notify-me--pdp-success--text {
    margin: 0px;
  }

  // if inside modal change design slightly
  .notify-me-modal__body & {
    padding: 0;
    border: none;
  }
}

.notfiy-me__modal-toggle {
  border: 2px solid $gray-200;
  padding: 1em;
}

.notify-me__modal-message {
  font-size: 2.2rem;
  margin-bottom: 18px;

  span {
    color: $red-500;
  }
}
