// Custom colors for disabled state. Be careful with changing these
// as they are specifically calibrated to just barely pass WCAG AA level.
$option-value-disabled-background: #f7f7f7;
$option-value-disabled-color: #717171;
$option-value-disabled-border: $gray-200;

// Helper mixins

@mixin diagonal-line-background($width, $background-color, $line-color) {
  background: linear-gradient(to top left,
    $background-color 0%,
    $background-color calc(50% - #{$width * 0.5}),
    $line-color 50%,
    $background-color calc(50% + #{$width * 0.5}),
    $background-color 100%);
}

//

.buy-section-option {
  &+& {
    margin-top: 2rem;
  }
}

.buy-section-option.is-error {
  margin-left: -5px;
  margin-right: -5px;
  padding-left: 5px;
  padding-right: 5px;
  box-shadow: 0 0 0 1px $red-500;

  .buy-section-option__error {
    display: block;
  }

  .buy-section__custom-options & {
    margin: 1px 1px 10px;
    padding: 5px 5px 0;
  }
}

.buy-section-option__error {
  display: none;
  background: $red-500;
  font-weight: bold;
  padding: 5px;
  margin-top: 5px;
  margin-left: -5px;
  margin-right: -5px;
  color: $white;
}

.buy-section-option__fieldset {
  // This might be a fieldset or a div, depending on the widget
  display: block;
  border: none;
  margin: 0;
  padding: 0;
}

.buy-section-option__legend {
  // This might be a label or a legend element, depending on the widget
  display: block;
  font-weight: 600;
  font-size: 1.4rem;
  text-transform: uppercase;
  width: 100%;
  margin-bottom: 0.25rem;
}

.buy-section-option__values-list {
  margin: -5px -5px;
}

.buy-section-option__value {
  position: relative;
  display: inline-block;
  padding: 5px;
}

// Dropdown variant

.buy-section-option--dropdown {
  .buy-section-option__values-list {
    margin: 0;
    position: relative;
  }

  .buy-section-option__select {
    // Set appearance to allow messing with padding etc.
    appearance: none;
    display: block;
    width: 100%;
    background: $white;
    transition: border-color 100ms ease;
    border: 2px solid $gray-300;
    border-radius: 2px;
    padding: 1rem 2rem;
    font-weight: 600;
    font-size: 1.6rem;
    cursor: pointer;
    color: $gray-600;

    &:hover {
      border-color: $gray-900;
    }

    &.has-selection {
      color: $gray-900;
      border-color: $gray-900;
    }
  }

  .buy-section-option__select-arrow {
    position: absolute;
    transform: translateY(-50%);
    right: 1.5rem;
    top: 50%;
    pointer-events: none;
    color: $gray-500;
    transition: color 100ms ease;

    svg {
      width: auto;
      height: 12px;
    }
  }

  .buy-section-option__select:hover+.buy-section-option__select-arrow,
  .buy-section-option__select.has-selection+.buy-section-option__select-arrow {
    color: $gray-900;
  }
}

// Button variant

.buy-section-option--buttons {
  .buy-section-option__values-list {
    display: flex;
    flex-wrap: wrap;
  }

  .buy-section-option__value {
    width: 33%;

    @include media-breakpoint-up(sm) {
      width: 25%;
    }

    @include media-breakpoint-up(md) {
      width: 33%;
    }

    @include media-breakpoint-up(lg) {
      width: 25%;
    }
  }

  .buy-section-option__value-link {
    // Adjust sizing
    font-size: 1.6rem;
    font-weight: 600;
    padding: 1rem;
    display: block;

    // Default state is available
    border-color: $gray-300;

    // Make sure we don't overflow
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      background: transparent;
      border-color: $gray-900;
      color: $gray-900;
    }

    /* Focusing with a keyboard will show a solid purple line. */
    &:focus-visible {
      outline: 2px dotted transparent;
      box-shadow: 0px 0px 0px 2px $purple-500;
      border-color: $purple-500;
    }

    /* Focusing with a mouse, touch, or stylus will show nothing. */
    &:focus:not(:focus-visible) {
      outline: none;
    }

    &.is-selected {
      border-color: $gray-900;
      background-color: $gray-900;
      color: $white;
    }

    &[data-state="unavailable"] {
      border-style: dashed;
      border-color: $option-value-disabled-border;
      color: $option-value-disabled-color;
      background: $option-value-disabled-background;

      &:hover {
        color: $option-value-disabled-color;
        border-color: $gray-400;
      }

      &.is-selected {
        background: $gray-200;
        color: $gray-800;
        border-color: $gray-500;
      }

      .buy-section--v2 & {
        border-style: solid;
        border-color: $option-value-disabled-background;
        background: transparent;
        pointer-events: none;

        &.is-selected {
          background: transparent;
          color: $option-value-disabled-color;
          border-color: $option-value-disabled-background;
        }

        .js-option_notify-icon {
          display: none;
        }
      }
    }

    &[data-state="out-of-stock"] {
      @include diagonal-line-background(3px, $white, $red-500);
    }

    &.button {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .buy-section--v2 & {
      span {
        pointer-events: none;
      }
    }
  }
}

@mixin switch-color($value, $color) {
  .buy-section-option__value-link[data-value="#{$value}"] {
    color: $color;

    &:hover {
      border-color: $color;
    }

    &.is-selected {
      background: $color;
      border-color: $color;
      color: $white;

      &[data-state="unavailable"] {
        background: $gray-200;
        color: $color;
      }
    }
  }
}

.buy-section-option[data-option="switch"] {
  @include switch-color("red", hsl(359, 91%, 37%));
  @include switch-color("blue", hsl(208, 100%, 30%));
  @include switch-color("brown", hsl(20, 55%, 33%));
}

// Swatches
.buy-section-option--swatches {
  .buy-section-option__value {}

  .buy-section-option__value-label {}

  .buy-section-option__value-link {
    display: block;
    width: 40px;
    height: 40px;
    box-shadow: inset 0 0 0 2px $white;
    border: 2px solid $gray-300;
    border-radius: 2px;
    background-color: $gray-500;
    transition: background 100ms ease, border-color 100ms ease;
    cursor: pointer;

    &:hover {
      border-color: $gray-900;
    }

    &.is-selected {
      border-color: $gray-900;
    }

    &[data-state="unavailable"] {
      border-style: dashed;

      &:hover {
        border-color: $gray-400;
      }

      .buy-section--v2 & {
        border-color: transparent;
        border-style: solid;
        pointer-events: none;

        opacity: 0.5;

        &:hover {
          border-style: solid;
          border-color: transparent;
        }

        &.is-selected {
          border-color: transparent;

          &:hover {
            border-color: transparent;
          }
        }
      }
    }

    &[data-state="out-of-stock"] {
      @include diagonal-line-background(3px, $white, $red-500);
    }

    .buy-section--v2 & {
      width: 33px;
      height: 33px;
      border-radius: 50px;
      box-shadow: none;
      border-color: transparent;
      outline-offset: 3px;
      outline: 3px solid transparent;

      &.is-selected {
        outline-color: $black;

        &:hover {
          outline-color: $black;
        }
      }

      &:hover {
        outline-color: $orange-500;
      }

      &:focus {
        outline-color: $purple-500;
      }

      &:active {
        outline-color: $orange-500;
      }
    }
  }
}

.buy-section-option--swatches {
  .buy-section-option__value-link {

    &[data-value="black"],
    &[data-value="onyx"] {
      background-color: #000;
    }
  }

  .buy-section-option__value-link[data-value="grey"] {
    background-color: #666;
  }

  .buy-section-option__value-link[data-value="red"] {
    background-color: #c00;
  }

  .buy-section-option__value-link[data-value="pink"] {
    background-color: #f696c3;
  }

  .buy-section-option__value-link {

    &[data-value="white"],
    &[data-value="snow"] {
      background-color: #f9f9f9;

      .buy-section--v2 & {
        border: 2px solid $black;

        &[data-state="unavailable"] {
          border: 2px solid $black;
        }
      }
    }
  }

  .buy-section-option__value-link[data-value="glacier"] {
    background: #51e9cc;
  }

  .buy-section-option__value-link[data-value="solar"] {
    background: #fc2c41;
  }

  .buy-section-option__value-link[data-value="winter-night"] {
    background: #102041;
  }

  .buy-section-option__value-link[data-value="jade"] {
    background: #00bf6f;
  }

  .buy-section-option__value-link[data-value="royal"] {
    background: #87189d;
  }

  .buy-section-option__value-link[data-value="cherry-red"] {
    background: #BD0519;
  }

  .buy-section-option__value-link[data-value="lilac"] {
    background: #CDA4FF;
  }

  .buy-section-option__value-link[data-value="mint"] {
    background: #B3E3C8;
  }

  .buy-section-option__value-link[data-value="rose-quartz"] {
    background: #F9E3DE;
  }

  .buy-section-option__value-link[data-value="crushed-dawn"] {
    background: linear-gradient(#eee120, #f8765f);
    background-image: url("../img/products/swatches/crushed-dawn.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .buy-section--v2 & {
      border: none;
    }
  }

  .buy-section-option__value-link[data-value="crushed-dusk"] {
    background: linear-gradient(#fe677a, #824aaa);
    background-image: url("../img/products/swatches/crushed-dusk.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .buy-section--v2 & {
      border: none;
    }
  }

  .buy-section-option__value-link[data-value="crushed-snow"] {
    background: linear-gradient($white,
        $black,
        $white,
        $black,
        $white,
        $black,
        $white,
        $black,
        $white,
        $black,
        $white,
        $black );
    background-image: url("../img/products/swatches/crushed-snow.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    color: $black  !important;

    .buy-section--v2 & {
      border: none;
    }
  }

  .buy-section-option__value-link[data-value="artist-series-lauren-asta"] {
    background: linear-gradient($black,
        $black,
        $white,
        $white,
        $white,
        $white,
        $red-500 );
    background-image: url("../img/products/swatches/lauren-asta.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    color: $black  !important;

    .buy-section--v2 & {
      border: none;
    }
  }

  .buy-section-option__value-link[data-value="artist-series-thankyoux"] {
    background: linear-gradient(#c52453, #1883af, #c7c317);
    background-image: url("../img/products/swatches/thankyoux.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border: none;

    .buy-section--v2 & {
      border: none;
    }
  }

  // Arctis Pro Booster Packs
  .buy-section-option__value-link[data-value="aurora"] {
    background: linear-gradient(#0bada3, #6f4181);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .buy-section--v2 & {
      border: none;
    }
  }

  .buy-section-option__value-link[data-value="flare"] {
    background: linear-gradient(45deg, #c7247e, #ce1d24, #303266, #1aa1d2);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .buy-section--v2 & {
      border: none;
    }
  }

  // Aerox customization colors
  .buy-section-option__value-link[data-value="jade"] {
    background-color: #00bf6f;
  }

  .buy-section-option__value-link[data-value="royal"] {
    background-color: #87189d;
  }

  .buy-section-option__value-link[data-value="grey"] {
    background-color: #b1b3b3;
  }

  .buy-section-option__value-link[data-value="gray"] {
    background-color: #b1b3b3;
  }

  .buy-section-option__value-link[data-value="orange"] {
    background-color: #fc4c02;
  }

  .buy-section-option__value-link[data-value="pink"] {
    background-color: #f57eb6;
  }

  .buy-section-option__value-link[data-value="purple"] {
    background-color: #bb29bb;
  }

  .buy-section-option__value-link[data-value="green"] {
    background-color: #00965e;
  }

  .buy-section-option__value-link[data-value="turquoise"] {
    background-color: #2ad2c9;
  }

  .buy-section-option__value-link[data-value="aqua"] {
    background-color: #2ad2c9;
  }

  .buy-section-option__value-link[data-value="blue"] {
    background-color: #2ad2c9;
  }

  .buy-section-option__value-link[data-value="pastel-blue"] {
    background-color: #9EBFCE;
  }

  .buy-section-option__value-link[data-value="bright-pink"] {
    background-color: #E0649D;
  }

  .buy-section-option__value-link[data-value="pine-green"] {
    background-color: #395544;
  }

  .buy-section-option__value-link[data-value="crystal"],
  .buy-section-option__value-link[data-value="ghost"] {
    background: rgb(216, 158, 238);
    background: linear-gradient(238deg,
        #d89eee 8%,
        #82cfff 30%,
        #e1f5e9 48%,
        #e1f5e9 60%,
        #d89eee 88%);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .buy-section--v2 & {
      border: none;
    }
  }
}
