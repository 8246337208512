/**
 * File containing all the SCSS Variables
 */

// base b/w
$white: hsl(0, 0%, 100%);
$black: hsl(0, 0%, 0%);

// glorious orange
$orange-100: hsl(25, 95%, 95%);
$orange-200: hsl(25, 95%, 88%);
$orange-300: hsl(18, 95%, 78%);
$orange-400: hsl(18, 98%, 65%);
$orange-500: hsl(18, 98%, 50%);
$orange-600: hsl(18, 98%, 40%);
$orange-700: hsl(18, 100%, 33%);
$orange-800: hsl(18, 100%, 25%);
$orange-900: hsl(18, 100%, 16%);

// subtle gray
$gray-100: hsl(0, 0%, 96%);
$gray-200: hsl(0, 0%, 92%);
$gray-300: hsl(0, 0%, 86%);
$gray-400: hsl(0, 0%, 75%);
$gray-500: hsl(217, 5%, 60%);
$gray-600: hsl(0, 0%, 46%);
$gray-700: hsl(0, 0%, 34%);
$gray-800: hsl(0, 0%, 22%);
$gray-900: hsl(0, 0%, 7%);

// victory yellow
$yellow-100: hsl(51, 75%, 96%);
$yellow-200: hsl(51, 75%, 92%);
$yellow-300: hsl(51, 91%, 87%);
$yellow-400: hsl(51, 89%, 78%);
$yellow-500: hsl(51, 90%, 60%);
$yellow-600: hsl(51, 90%, 45%);
$yellow-700: hsl(48, 96%, 35%);
$yellow-800: hsl(48, 100%, 25%);
$yellow-900: hsl(48, 100%, 16%);

// emerald green
$green-100: hsl(135, 75%, 96%);
$green-200: hsl(135, 75%, 92%);
$green-300: hsl(135, 75%, 85%);
$green-400: hsl(135, 75%, 75%);
$green-500: hsl(140, 73%, 56%);
$green-600: hsl(140, 71%, 45%);
$green-700: hsl(140, 56%, 32%);
$green-800: hsl(140, 80%, 22%);
$green-900: hsl(140, 100%, 16%);

// ultramarine blue
$blue-100: hsl(230, 60%, 97%);
$blue-200: hsl(230, 60%, 88%);
$blue-300: hsl(230, 60%, 75%);
$blue-400: hsl(235, 60%, 60%);
$blue-500: hsl(243, 65%, 40%);
$blue-600: hsl(243, 65%, 33%);
$blue-700: hsl(243, 67%, 25%);
$blue-800: hsl(243, 75%, 20%);
$blue-900: hsl(243, 100%, 16%);

// loyal purple
$purple-100: hsl(245, 63%, 95%);
$purple-200: hsl(245, 63%, 82%);
$purple-300: hsl(250, 63%, 72%);
$purple-400: hsl(261, 63%, 60%);
$purple-500: hsl(261, 63%, 53%);
$purple-600: hsl(261, 63%, 43%);
$purple-700: hsl(261, 63%, 33%);
$purple-800: hsl(261, 63%, 23%);
$purple-900: hsl(261, 100%, 16%);

// racing red
$red-100: hsl(15, 75%, 96%);
$red-200: hsl(20, 75%, 89%);
$red-300: hsl(5, 100%, 75%);
$red-400: hsl(0, 100%, 63%);
$red-500: hsl(352, 95%, 46%);
$red-600: hsl(352, 95%, 38%);
$red-700: hsl(352, 96%, 30%);
$red-800: hsl(352, 94%, 22%);
$red-900: hsl(352, 100%, 16%);

// Social colors
$facebook-blue: hsl(221, 44%, 41%);
$twitter-blue: hsl(203, 89%, 53%);
$instagram-pink: hsl(336, 69%, 52%);
$youtube-red: hsl(0, 100%, 50%);
$discord-purple: hsl(227, 58%, 65%);
$twitch-purple: hsl(261, 43%, 45%);
$reddit-orange: hsl(16, 100%, 50%);
$linkedin-blue: hsl(201, 100%, 35%);
$tiktok-black: hsl(0, 0%, 0%);

// Product specific swatches
$gaia-green: hsl(62, 72%, 54%);

// Moments
$moments-blue: #16191e;
$moments-hawkes-blue: #d0d9f6;
$moments-hawkes-gray: #a7aec4;
$moments-martinique: #323943;

$moments-primary-purple-90: #4b60df;
$moments-secondary-grey-10: #2c3034;

$moments-background-color: #16191e;
$moments-background-color-lighter: #191c20;
$moments-accent-color: #5168f4;
$moments-contrast-color: #d0d9f6;
$moments-accent-color-fade: rgba($moments-accent-color, 0.3);
$moments-accent-color-faded: #1f2539;
$moments-background-color-gray: #293239;

// Scaffolding
$body-bg: $black;

// Typography
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
  "Segoe UI Symbol";
$font-family-base: $font-family-sans-serif;

$font-size-h1: 5.2rem;
$font-size-h2: 3.2rem;
$font-size-h3: 2.4rem;
$font-size-h4: 1.7rem;
$font-size-h5: 1.5rem;
$font-size-h6: 1.2rem;

$headings-font-family: "Replica Pro", Helvetica, Arial, sans-serif;
$body-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
  "Segoe UI Symbol";
$headings-font-weight: 900;

// Media queries breakpoints
$screen-xs-min: 480px; // Extra small screen / phone
$screen-sm-min: 768px; // Small screen / tablet
$screen-md-min: 992px; // Medium screen / desktop
$screen-lg-min: 1200px; // Large screen / wide desktop
$screen-xl-min: 1600px; // Extra Large screen / full hd

$screen-xl-max: 1920px; // @TODO: this shouldn't be needed?
$screen-lg-max: ($screen-xl-min - 1);

// Grid system
$grid-gutter-width: 30px;
$grid-float-breakpoint: $screen-md-min;

// Container sizes
$container-wide-desktop: 1400px;
$container-xlarge-desktop: (1540 + $grid-gutter-width);
$container-xl: $container-xlarge-desktop;

// Navbar
$navbar-height: 64px;
$navbar-border-radius: 0;

$navbar-default-color: $white;
$navbar-default-bg: $gray-900;

$navbar-default-link-color: $white;
$navbar-default-link-hover-color: $white;
$navbar-default-link-hover-bg: transparent;
$navbar-default-link-active-color: $white;
$navbar-default-link-active-bg: $gray-900;

$navbar-default-toggle-hover-bg: $gray-400;
$navbar-default-toggle-icon-bar-bg: $white;
$navbar-default-toggle-border-color: $gray-600;

// Navs
$nav-link-padding: 10px 30px;

// Forms
$border-radius-base: 0;
$input-border-focus: $gray-300;

// Path variables
$img: "../img";

// Grid v2

$grid-columns: 12;
$grid-breakpoints: (
  xs: 0,
  sm: 768px,
  md: 992px,
  lg: 1200px,
  xl: 1600px,
  // ultrawide breakpoint to be deleted
  uw: 2000px,
);
$container-max-widths: (
  sm: (
    720px + $grid-gutter-width,
  ),
  md: (
    940px + $grid-gutter-width,
  ),
  lg: (
    1140px + $grid-gutter-width,
  ),
  xl: (
    1540px + $grid-gutter-width,
  ),
  // ultrawide breakpoint to be deleted
  uw:
    (
      1940px + $grid-gutter-width,
    ),
);

// Tooltips

//** Tooltip max width
$tooltip-max-width: 250px;

// This is probably where we want to go, but for now, the grid is aligned
// with the current one.
// $grid-breakpoints: (
//   xs: 0,
//   sm: 600px,
//   md: 900px,
//   lg: 1200px,
//   xl: 1800px
// );
// $container-max-widths: (
//   sm: 540px,
//   md: 840px,
//   lg: 1140px,
//   xl: 1740px
// );
// $grid-gutter-width: 30px;
