
.step-actions {
  margin: 0;
  align-items: center;
  flex-direction: column;
  display: flex;
  justify-content: space-between;

  @include media-breakpoint-up(sm) {
    flex-direction: row-reverse;
    flex-wrap: wrap;
  }
}

.step-actions__primary,
.step-actions__secondary {
  width: 100%;

  @include media-breakpoint-up(sm) {
    width: auto;
  }
}

.step-actions__primary {
  display: block;
  text-align: center;

  @include media-breakpoint-up(sm) {
    text-align: right;
  }

  .button--large {

    @include media-breakpoint-up(lg) {
      min-width: 445px;
    }
  }
}

.step-actions__disclaimer {
  width: 100%;
  page-break-before: always;
  text-align: left;
  margin-top: 15px;

  @include media-breakpoint-up(sm) {
    order: 1;
    text-align: right;
  }
}

.step-actions__secondary {
  text-align: left;
  padding-top: 20px;
  padding-bottom: 5em;
  font-size: 1.4rem;

  @include media-breakpoint-up(sm) {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.step-actions__back {
  color: $gray-700;
  text-decoration: none;
  margin: 15px 0;

  &:hover {
    color: $gray-700;
    text-decoration: underline;
  }
}
