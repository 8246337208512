/**
 * File containing all the Osano styling
 */
.osano-cm-button {
  border-radius: 2px;
  border-color: transparent;
}

.osano-cm-dialog:after {
  position: absolute;
  top: 30px;
  left: 28px;
  right: 28px;
  height: 40px;
  content: '';
  background: url("../img/logos/snipe-steelseries.svg");
  background-position: center;
  background-size: 200px;
  background-repeat: no-repeat;
}

.osano-cm-dialog__content.osano-cm-content::before {
  content: "We value your privacy!\A";           
  // color: #000;           
  font-weight: bold;           
  font-size: 2.5rem;           
  display: block;           
  padding-bottom: 1em;
  padding-top: 80px;
  margin: auto;
  font-family: "Replica Pro";
}

// .osano-cm-dialog .osano-cm-content__message { 
//   padding: 0px 0px 0 0px;
// }

.osano-cm-window__widget {
  &.with-sticky-nav {
    bottom: 8px;
    left: 64px;
    @include media-breakpoint-up(md) {
      bottom: 21px;
      left: 98px;
    }
  }

  svg {
    --size: 38px;
    @include media-breakpoint-up(md) {
      --size: 50px;
    }

    width: var(--size);
    height: var(--size);
  }
}

.osano-cm-info-dialog {
  svg {
    width: unset;
    height: unset;
  }
}

.osano-cm-dialog__close:focus {
  background-color: transparent;
  border-color: transparent;
  stroke: white;
}
