.shopping-links {
  display: flex;
  flex-direction: column;
}

.shopping-links__cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;

  a {
    padding: 6px 24px;
    background-color: $gray-100;
    height: auto;
  }

  img {
    width: 100%;
  }
}
