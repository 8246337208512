.interstitial-modal__header {
  display: flex;
  align-items: center;

  h2 {
    margin: 0;
    margin-right: auto;
  }
}

.interstitial-modal__close-button {
  display: flex;
  color: $gray-700;

  svg {
    width: 1.4em;
    height: 1.4em;
  }
}

.interstitial-modal__footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
}

.interstitial-modal__continue-button {
  display: inline-flex;
  align-items: center;
  gap: 12px;
  font-weight: bold;
  text-decoration: none;
  color: $gray-900;

  svg {
    transform: translateX(0);
    transition: transform 200ms ease;
  }

  &:hover,
  &:focus {
    svg {
      transform: translateX(6px);
    }
  }
}
