.product-upgrades {
  --font-weight: 600;
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 28px;
  margin-bottom: 28px;
  padding: 24px;
  background-color: $gray-100;
  max-height: 222px;

  @include media-breakpoint-up(md) {
    margin-bottom: 0;

  }
}

.product-upgrades__media {
  display: flex;

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
    aspect-ratio: 1/1;
  }
}

.product-upgrades__content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-weight: var(--font-weight);
  height: 100%;
  margin-right: auto;

  >* {
    margin: 0;
  }

  .button {
    width: 148px;
  }
}

.product-upgrades__product-meta {
  display: flex;
  flex-direction: column;
  gap: 6px;
  color: $gray-700;

  >* {
    white-space: nowrap;
  }
}

.product-upgrades__product-price {
  .buy-section-price__price {
    font-weight: var(--font-weight);
    font-size: 1.6rem;
  }
}
