
.styleguide__nav {
  padding: 30px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif !important;

  @include media-breakpoint-up(sm) {
    position: fixed;
    top: 0;
    left: 0;
    width: 300px;
  }
}

.styleguide__main {
  padding: 30px 60px;
  max-width: 1440px;

  @include media-breakpoint-up(sm) {
    padding-left: 300px;
  }

  // Use a safe font for reading
  > h1,
  > h2,
  > h3,
  > h4,
  > p,
  > ul,
  > ol {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
  }

  > h1,
  > h2,
  > h3,
  > h4 {
    margin: 1.414em 0 0.5em;
  }

  > h1 {
    margin-top: 0;
    font-size: 2.441em;
  }
  > h2 {font-size: 1.953em;}
  > h3 {font-size: 1.563em;}
  > h4 {font-size: 1.25em;}

  > p,
  > ul,
  > ol {
    margin-bottom: 1.3em;
  }

  pre {
    font-size: 1.4rem;
    border: none;
    background: none;
    padding: 0;
    margin: 0;
  }

  code {
    padding: 5px 6px;
    font-size: 1.2rem;
    color: $orange-500;
    background-color: $orange-100;
    border-radius: 4px;
  }

  hr {
    height: .25em;
    padding: 0;
    margin: 24px 0;
    background-color: $gray-100;
    border: 0;
    border-radius: 2px;
  }
}

.styleguide__nav-section {
  span {
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
    color: $gray-400;
  }

  ul {
    padding: 0 0 20px;
    list-style: none;
  }

  a {
    color: $gray-900;
    text-decoration: none;
    padding: 5px 0;
    display: block;

    &:hover {
      text-decoration: none;
      color: $black;
    }
  }

  li.is-selected a {
    color: $orange-500;
    &:hover {
      color: $orange-600;
    }
  }
}

.styleguide__example {
  margin-bottom: 1.3em;
}

.styleguide__result {
  border: 2px solid $gray-100;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
  padding: 13px;
}

.styleguide__code {
  padding: 15px;
  background: $gray-100;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;

  .codehilite {
    background: none;
  }
}


.styleguide__example--dark {
  .styleguide__result {
    background: $gray-800;
    border-color: $gray-800;
  }
}

.styleguide__colors {
  border-collapse: separate;
  border-spacing: 0 20px;
}

.styleguide__swatch-header {
  text-align: right;

  pre {
    margin-right: 10px;
  }
}

.styleguide__swatch {
  display: inline-block;
  text-align: center;
  width: 70px;
  margin: 0 5px;

  pre {
    padding: 5px 0;
    margin: 0;
  }
}

.styleguide__swatch-color {
  display: block;
  height: 60px;
  background: red;
  border-radius: 4px;

  &.black { background-color: $black; }
  &.white { background-color: $white; }

  &.orange-100 { background-color: $orange-100; }
  &.orange-200 { background-color: $orange-200; }
  &.orange-300 { background-color: $orange-300; }
  &.orange-400 { background-color: $orange-400; }
  &.orange-500 { background-color: $orange-500; height: 75px; }
  &.orange-600 { background-color: $orange-600; }
  &.orange-700 { background-color: $orange-700; }
  &.orange-800 { background-color: $orange-800; }
  &.orange-900 { background-color: $orange-900; }

  &.gray-100 { background-color: $gray-100; }
  &.gray-200 { background-color: $gray-200; }
  &.gray-300 { background-color: $gray-300; }
  &.gray-400 { background-color: $gray-400; }
  &.gray-500 { background-color: $gray-500; height: 75px; }
  &.gray-600 { background-color: $gray-600; }
  &.gray-700 { background-color: $gray-700; }
  &.gray-800 { background-color: $gray-800; }
  &.gray-900 { background-color: $gray-900; }

  &.yellow-100 { background-color: $yellow-100; }
  &.yellow-200 { background-color: $yellow-200; }
  &.yellow-300 { background-color: $yellow-300; }
  &.yellow-400 { background-color: $yellow-400; }
  &.yellow-500 { background-color: $yellow-500; height: 75px; }
  &.yellow-600 { background-color: $yellow-600; }
  &.yellow-700 { background-color: $yellow-700; }
  &.yellow-800 { background-color: $yellow-800; }
  &.yellow-900 { background-color: $yellow-900; }

  &.green-100 { background-color: $green-100; }
  &.green-200 { background-color: $green-200; }
  &.green-300 { background-color: $green-300; }
  &.green-400 { background-color: $green-400; }
  &.green-500 { background-color: $green-500; height: 75px; }
  &.green-600 { background-color: $green-600; }
  &.green-700 { background-color: $green-700; }
  &.green-800 { background-color: $green-800; }
  &.green-900 { background-color: $green-900; }

  &.blue-100 { background-color: $blue-100; }
  &.blue-200 { background-color: $blue-200; }
  &.blue-300 { background-color: $blue-300; }
  &.blue-400 { background-color: $blue-400; }
  &.blue-500 { background-color: $blue-500; height: 75px; }
  &.blue-600 { background-color: $blue-600; }
  &.blue-700 { background-color: $blue-700; }
  &.blue-800 { background-color: $blue-800; }
  &.blue-900 { background-color: $blue-900; }

  &.purple-100 { background-color: $purple-100; }
  &.purple-200 { background-color: $purple-200; }
  &.purple-300 { background-color: $purple-300; }
  &.purple-400 { background-color: $purple-400; }
  &.purple-500 { background-color: $purple-500; height: 75px; }
  &.purple-600 { background-color: $purple-600; }
  &.purple-700 { background-color: $purple-700; }
  &.purple-800 { background-color: $purple-800; }
  &.purple-900 { background-color: $purple-900; }

  &.red-100 { background-color: $red-100; }
  &.red-200 { background-color: $red-200; }
  &.red-300 { background-color: $red-300; }
  &.red-400 { background-color: $red-400; }
  &.red-500 { background-color: $red-500; height: 75px; }
  &.red-600 { background-color: $red-600; }
  &.red-700 { background-color: $red-700; }
  &.red-800 { background-color: $red-800; }
  &.red-900 { background-color: $red-900; }
}
