.facet {
  padding: 10px 0;

  & + & {
    border-top: 2px solid $gray-300;
  }
}

.facet__header {
  background: none;
  border: none;
  color: $gray-900;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;

  &.facet__header--in-stock {
    cursor: revert;
  }
}

.facet__collapse-arrow {
  color: $gray-900;
  margin: 0 5px;
  height: 16px;

  svg {
    width: 16px;
    height: 16px;
    transform: rotate(180deg);
    transition: transform 200ms ease;
  }
}

.facet__title {
  margin: 10px 0;
  @include body-100;
  font-weight: bold;
}

.facet__body {
  max-height: 1000px;
  overflow: hidden;
  transition: max-height 300ms ease;
}

// Variants

.facet--collapsible {
  &.is-collapsed {
    .facet__collapse-arrow {
      svg {
        transform: rotate(0deg);
      }
    }
    .facet__body {
      max-height: 0;
    }
  }
}
