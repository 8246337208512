@mixin containerFlex {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

@mixin container {
  margin-right: auto;
  margin-left: auto;
  padding-right: $grid-gutter-width;
  padding-left: $grid-gutter-width;
  max-width: 1540px;
}

@mixin containerFull {
  max-width: 1940px;
  width: 100%;
}
