.checkout-progress {
  // For breadcrumb-like navigation for checkout steps
  padding-bottom: 3.5em;

  @include media-breakpoint-up(md) {
    margin: 0.5em 0 1.5em;
    padding-bottom: 0;
  }
}

.checkout-progress__list {
  padding: 0;
  margin: 0;
  list-style: outside none;
  display: none;

  @include media-breakpoint-up(md) {
    display: block;
  }
}

.checkout-progress__step {
  display: inline-block;
  margin-right: 0;
  font-size: 1.4rem;

  @include media-breakpoint-up(md) {
    margin-right: 1em;

    + .checkout-progress__step::before {
      content: '>';
      line-height: 1;
      margin-right: 0.7em;
      color: $gray-500;
      font-size: 1.4rem;
    }
  }
}

.checkout-progress__step {
  color: $gray-700;
}

.checkout-progress__step--complete {
  text-align: center;
}

.checkout-progress__step--current {
  // Current step is not a link
  color: $gray-900;
  font-weight: 500;

  @include media-breakpoint-up(md) {
    background-color: inherit;
  }
}

// mobile progress bar
.checkout-progress__list--mobile {
  width: inherit;
  margin-left: -3.5em;

  li {
    float: left;
    list-style-type: none;
    width: 25%;
    font-size: 1.2rem;
    position: relative;
    text-transform: uppercase;
    text-align: center;

    // circles
    &::before {
      display: block;
      width: 12px;
      height: 12px;
      left: 2em;
      content: '';
      line-height: 12px;
      border-radius: 50%;
      margin: 0 auto 10px auto;
      text-align: center;
    }

    // connecting lines
    &::after {
      display: block;
      content: '';
      width: 100%;
      height: 2px;
      position: absolute;
      top: 0.4em;
      left: -50%;
      z-index: -1;
    }

    &:first-child::after {
      content: none;
    }
  }

  .checkout-progress__step--current {
    &::before {
      color: $gray-800;
      border: 1px solid $gray-800;
      background-color: $gray-800;
    }
    &::after {
      background-color: $gray-500;
    }
  }

  .checkout-progress__step--complete {
    &::before {
      color: $purple-300;
      border: 1px solid $purple-300;
      background-color: $purple-300;
    }
    &::after {
      background-color: $purple-300;
    }
  }


  .checkout-progress__step--incomplete {
    &::before {
      color: $gray-500;
      border: 2px solid $gray-500;
      background-color: $white;
    }
    &::after {
      background-color: $gray-500;
    }
  }

  @include media-breakpoint-up(md) {
    display: none;
  }
}
