
.review {
  text-align: center;
  text-transform: uppercase;
  font-family: $headings-font-family;
}

.review__body {
}

.review__content {
  font-size: 1.5em;
  font-weight: 900;
  line-height: 1;
  padding: 5px;
}

.review__product {
  font-size: 0.875em;
  font-weight: 700;
  line-height: 0.9;
  padding: 2px 0 5px;
}

.review__rating {
}

.review__star {
  display: inline-block;

  & + & {
    margin-left: 5px;
  }

  svg {
    height: 1.5em;
    width: auto;
  }
}

.review__star--0 {
  .star__1,
  .star__2,
  .star__3,
  .star__4 {
    color: transparent !important;
  }
}

.review__star--1 {
  .star__2,
  .star__3,
  .star__4 {
    color: transparent !important;
  }
}

.review__star--2 {
  .star__3,
  .star__4 {
    color: transparent !important;
  }
}

.review__star--3 {
  .star__4 {
    color: transparent !important;
  }
}

.review__logo {
  font-family: $headings-font-family;
  font-weight: 900;
  font-size: 0.875em;

  svg {
    display: inline-block;
    height: 1.6em;
    width: auto;
  }

  img {
    height: 3em;
    width: auto;
  }
}


// Award layout

.review--award {
  .review__body {
    margin-bottom: 0.3em;
    border: 0.3em solid $gray-900;
    border-left-width: 0;
    border-right-width: 0;
  }

  .review__content {
    font-size: 1.75em;
  }
}


// Color variations

.review--white {

}

.review--orange {

}
