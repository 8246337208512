// Shipping options

@import "mixins/typography";

.payment-method {
  .choice-box__label {
    display: flex;
    flex-basis: content;

    input {
      cursor: pointer;

    }
  }

  &.is-active .choice-box__extra {
    max-height: 400px;
  }
}

.payment-method-title {
  display: flex;
  align-items: center;
}

.payment-method__widget {
  padding-right: 12px;
  // TODO: this is some annoying base styles we have to overwrite
  vertical-align: top;
  margin: 3px 0 0 0;
}

.payment-method__header {
  flex: 1;
  margin: 0;

  small {
    display: block;
    color: $gray-700;
    margin-top: 5px;

    @include media-breakpoint-up(sm) {
      display: inline-block;
      margin-top: 0;
      margin-left: 5px;
    }
  }
}

.payment-method__header--paypal {
  display: inline-block;
  background-image: url('../img/checkout/payment-icons/paypal-logo-14px.png');
  background-repeat: no-repeat;
  background-position: 0 70%;

  @include media-breakpoint-up(sm) {
    background-image: url('../img/checkout/payment-icons/paypal-logo-16px.png');
  }

  .payment-method__header--text {
    display: inline-block;
    margin-left: 70px;
    margin-bottom: 0;

    small {
      display: block;

      @include media-breakpoint-up(sm) {
        display: inline-block;
      }
    }
  }
}

.payment-method__header--affirm {
  display: inline-block;
  background-image: url('../img/checkout/payment-icons/affirm_payment_icon-14px.png');
  background-repeat: no-repeat;
  background-position: top 4px left;

  @include media-breakpoint-up(sm) {
    background-image: url('../img/checkout/payment-icons/affirm_payment_icon-16px.png');
    background-position: bottom 6px left;
  }

  .payment-method__header--text {
    display: inline-block;
    margin-left: 70px;
    margin-bottom: 0;

    small {
      display: block;

      @include media-breakpoint-up(sm) {
        display: inline-block;
      }
    }
  }
}

.payment-method__icons {
  margin-top: 13px;
  align-self: center;
}

.payment-method__icon {
  display: inline-block;
  border: 1px solid $gray-200;
  border-radius: 4px;
  height: 20px;
  width: 30px;
  background: no-repeat center right;
  background-size: cover;
  transition: opacity 250ms ease;
  margin: 12px 0 1px;

  &+& {
    margin-left: 5px;
  }

  @include media-breakpoint-up(sm) {
    height: 27px;
    width: 40px;

    &+& {
      margin-left: 10px;
    }
  }

  &.is-dimmed {
    opacity: 0.2;
  }

  &.payment-method__icon--visa {
    background-image: url('../img/checkout/payment-icons/visa.png');
  }

  &.payment-method__icon--mastercard {
    background-image: url('../img/checkout/payment-icons/mastercard.png');
  }

  &.payment-method__icon--discover {
    background-image: url('../img/checkout/payment-icons/discover.png');
  }

  &.payment-method__icon--amex {
    background-image: url('../img/checkout/payment-icons/amex.png');
  }

  // &.payment-method__icon--maestro {
  //   background-image: url('../img/checkout/payment-icons/maestro.png');
  // }
  // &.payment-method__icon--paypal {
  //   background-image: url('../img/checkout/payment-icons/paypal.png');
  // }
  // &.payment-method__icon--affirm {
  //   background-image: url('../img/checkout/payment-icons/affirm.png');
  // }
  // &.payment-method__icon--alipay {
  //   background-image: url('../img/checkout/payment-icons/alipay.png');
  // }
}

.payment-method__redirect {
  color: $gray-700;
  text-align: center;
  padding: 0 2em;

  svg {
    margin-left: -1.2em;
    width: 12em;
    height: auto;

    .no-fill {
      color: transparent;
    }
  }

  p {
    margin: 5px 0;
    font-size: 85%;
    padding: 0;
    color: $gray-700;

    @include media-breakpoint-up(sm) {
      padding: 0 20%;
    }
  }
}

.payment-method__cvc-tooltip {
  text-align: left;

  h5 {
    margin: 10px 0 5px;
    @include body-100;
    font-weight: 500;
  }

  p {
    font-size: 90%;
  }
}
