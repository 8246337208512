.buy-section-value-adds {
  display: none;

  &.is-visible {
    display: block;
  }
}

.buy-section-value-adds__header {
  // border-bottom: 1px solid $gray-200;
}

.buy-section-value-adds__info {
  // border-bottom: 1px solid $gray-200;
  color: $gray-700;
  padding-top: 12px;
  font-weight: bold;
}

.buy-section-value-adds__title {
  @include body-100();
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 600;
  flex-grow: 1;
}

.buy-section-value-adds__total {
  font-size: 1.4rem;
  color: $gray-600;
}

.buy-section-value-adds__content {
  margin-top: 12px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      border: 2px solid $gray-300;
      border-radius: 2px;
      background-color: $gray-200;
      padding: 1em;
      margin: 10px 0;

      img {
        padding-top: 1em;
        width: 100%;
      }

      span {
        font-weight: bold;
      }
    }
  }
}
