.charger-icon {
    width: 25px;
    padding-top: 25px;
    padding-bottom: 25px;
    display: None;
}

.charger-icon.is-visible {
    display: inline;
}
