// Glide.js imports
@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";
@import "node_modules/@glidejs/glide/src/assets/sass/glide.theme";

.catalog-list {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.catalog-list-item {
  // only deals with grid-style layout and flexbox breakpoints etc
  // can contain a product, but could also be an ad
  margin: 0;
  flex-direction: column;
  border: 2px solid transparent;
  border-radius: 4px;
  transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-property: box-shadow;

  &:hover,
  &:active,
  &:focus {
    @include media-breakpoint-up(sm) {
      z-index: 10;
      border-color: $black;
      box-shadow: 0px 5px 14px -5px rgba($black, 0.5);
    }
  }

  // grid responsive styles
  flex: 0 0 47.5%;
  flex-basis: 50%;

  @include media-breakpoint-up(lg) {
    flex: 0 0 31%;
    flex-basis: 33.33%;
  }

  @include media-breakpoint-up(xl) {
    flex: 0 0 23.5%;
    flex-basis: 25%;
  }

  &.item--related {
    border: none;
    box-shadow: none;
  }

  &.smurf {
    pointer-events: none;
  }
}

.catalog-list-product {
  position: relative;
}

.catalog-list-product__wrapper {
  height: 100%;
  padding: 0;
  background-color: $white;
  border-radius: 4px;
  width: 100%;
  transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-property: box-shadow;
  border: 2px solid transparent;

  &:hover,
  &:active,
  &:focus {
    @include media-breakpoint-up(sm) {
      border-color: $black;
      box-shadow: 0px 5px 14px -5px rgba($black, 0.5);
    }
  }


  @include media-breakpoint-up(sm) {
    position: relative;
    height: 100%;
  }

  &.wrapper--related {
    box-shadow: none;

    &:hover,
    &:active,
    &:focus,
    &:focus-within {
      @include media-breakpoint-up(sm) {
        z-index: 10;
        position: relative;
        border-color: transparent;

        & .catalog-list-product__related-items {
          position: absolute;
        }

        .glide__slides {
          display: flex;
        }

        .glide__arrows {
          display: block;
        }
      }
    }


  }
}

.catalog-list-product__link {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;
  color: $gray-900;
  text-decoration: none;
  margin: 0;
  padding: 0.5em 0;
  border-radius: 2px;

  @include media-breakpoint-up(sm) {
    padding: 0.5em;
  }

  &:hover,
  &:active,
  &:focus {
    position: relative;
    z-index: 15;
    color: $gray-900;
    text-decoration: none;

    .catalog-list-product__user-exclusive {
      z-index: 30;
    }

    // TODO: waiting for AB test
    // .catalog-list-product__user-exclusive-overlay {
    //   display: block;
    //   position: absolute;
    //   z-index: 20;
    // }
  }

  &:focus {
    @include media-breakpoint-up(sm) {
      outline-offset: -1px;
    }
  }
}

.catalog-list-product__image {
  position: relative;
  margin: 0 auto;
  width: 100%;
  aspect-ratio: 1 / 1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-color: $gray-100;

  @include media-breakpoint-up(sm) {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }

  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: $gray-100;
    opacity: 1;
    transition: opacity 100ms ease-in;
    object-fit: contain;
  }
}

.catalog-list-product__user-exclusive {
  position: absolute;
  top: 1em;
  left: 1em;
  padding: 4px 8px;
  background-color: $gray-200;
  border-radius: 50px;
  color: $gray-900;
  font-size: 1.2rem;

  svg {
    width: 13px;
    height: 13px;
    color: $purple-500;
    vertical-align: text-top;
  }

  @include media-breakpoint-up(sm) {
    padding: 8px 12px;
    font-size: 1.4rem;

    svg {
      width: 15px;
      height: 15px;
    }
  }
}

.catalog-list-product__member-price {
  color: $purple-500;
  font-weight: bold;
}

.catalog-list-product__user-exclusive-overlay {
  display: none;
  overflow: hidden;
  background: rgba($gray-200, 0.8);
  width: 100%;
  height: 100%;

  p {
    font-size: 1.8rem;
    padding: 4em 1em 0;
  }
}

.catalog-list-product__wrapper {

  .catalog-list-product__image {
    img.tile_hover {
      object-fit: cover;
      opacity: 0;
    }
  }

  &:hover,
  &:active,
  &:focus {
    .catalog-list-product__image {

      &.with-hover {
        img:first-child {
          opacity: 0;
        }

        img.tile_hover {
          opacity: 1;
        }
      }


    }
  }
}

.catalog-list-product__content {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 10px 5px 0;

  @include media-breakpoint-up(sm) {
    padding: 15px 10px 0;
  }
}

// Ribbon

.catalog-list-product__ribbon {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: -18px;
  left: 0;
  width: 100%;
  padding: 2px 10px;
  font-size: 1rem;
  font-weight: 600;

  @include media-breakpoint-up(sm) {
    top: -28px;
    padding: 4px 10px;
    font-size: 1.8rem;
    font-weight: bold;
  }

  .catalog-list-product__ribbon-left,
  .catalog-list-product__ribbon-right {
    display: flex;
    flex: 1 0 50%;
  }

  .catalog-list-product__ribbon-right {
    justify-content: flex-end;
  }

  &.catalog-list-product__ribbon--default {
    color: $white;
    background: $black;
  }

  &.catalog-list-product__ribbon--new {
    color: $white;
    background: $black;
  }

  &.catalog-list-product__ribbon--sale {
    color: $white;
    background: $orange-500;
  }

  &.catalog-list-product__ribbon--bundle {
    color: $white;
    background: $orange-500;
  }

  &.catalog-list-product__ribbon--members-price {
    color: $purple-500;
    background: transparent;
  }
}

.catalog-list-product__name {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 1.6rem;
  font-weight: 900;
  text-transform: uppercase;

  @include media-breakpoint-up(sm) {
    font-size: 1.8rem;
  }

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  span {
    // Need styles to differntiate from product name
  }

  &.smurf {
    height: 20px;
    background: $gray-100;
    border-radius: 4px;
  }
}

// Prices

.catalog-list-product__prices {
  display: block;
  text-align: left;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.2;
  margin: 0 0 5px;

  @include media-breakpoint-up(lg) {
    font-size: 1.6rem;
  }

  &.is-reduced {
    .catalog-list-product__msrp {
      display: inline-block;
      margin-right: 0;
      text-decoration: line-through;

      @include media-breakpoint-up(sm) {
        margin-right: 5px;
      }
    }

    .catalog-list-product__current-price {
      color: $orange-500;
      font-weight: bolder;
    }

    .current-price--membership-price {
      color: $purple-500;
      font-weight: bolder;
    }
  }

  .vanadium {
    color: #a35af9;
  }

  &.smurf {
    width: 100px;
    height: 16px;
    background: $gray-100;
    border-radius: 4px;
  }
}

.catalog-list-product__msrp {
  display: none;
}

// Description

.catalog-list-product__description {
  ul {
    // hides functionality for mobile tiles
    display: none;
    margin: 0 0 10px;
    padding: 0 0 0 15px;
    font-size: 1.4rem;

    @include media-breakpoint-up(sm) {
      display: block;
    }

    // Base Styling for WYSIWYG field
    li {
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  p {
    // hides functionality for mobile tiles
    display: none;

    @include media-breakpoint-up(sm) {
      display: block;
    }

    &+p {
      margin: 0 0 10px;
    }
  }

  &.smurf {
    height: 80px;
    background: $gray-100;
    border-radius: 4px;
  }
}

.catalog-list-product__related-text {
  span {
    font-size: 1.4rem;
    font-weight: 600;
    color: $gray-600;
  }
}

.catalog-list-product__related-border {
  .catalog-list-product__wrapper.wrapper--related:hover &,
  .catalog-list-product__wrapper.wrapper--related:focus &,
  .catalog-list-product__wrapper.wrapper--related:active &,
  .catalog-list-product__wrapper.wrapper--related:focus-within & {
    @include media-breakpoint-up(sm) {
      border: 2px solid black;
      position: absolute;
      border-bottom: 0;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.catalog-list-product__related-shadow {
  transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-property: box-shadow;

  .catalog-list-product__related-items & {
    .catalog-list-product__wrapper.wrapper--related:hover &,
    .catalog-list-product__wrapper.wrapper--related:focus &,
    .catalog-list-product__wrapper.wrapper--related:active &,
    .catalog-list-product__wrapper.wrapper--related:focus-within & {
      @include media-breakpoint-up(sm) {
        box-shadow: 0px 14px 14px -4px rgba($black, 0.5);
      }
    }
  }

  .catalog-list-product__wrapper.wrapper--related:hover &,
  .catalog-list-product__wrapper.wrapper--related:focus &,
  .catalog-list-product__wrapper.wrapper--related:active &,
  .catalog-list-product__wrapper.wrapper--related:focus-within & {
    @include media-breakpoint-up(sm) {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      position: absolute;
      box-shadow: 0px 5px 14px -5px rgba($black, 0.5);
      height: 100%;
      border-radius: 4px;
    }
  }
}

.catalog-list-product__related-items {
  .catalog-list-product__wrapper.wrapper--related:hover &,
  .catalog-list-product__wrapper.wrapper--related:focus &,
  .catalog-list-product__wrapper.wrapper--related:active &,
  .catalog-list-product__wrapper.wrapper--related:focus-within & {
    @include media-breakpoint-up(sm) {
      border-bottom: 2px solid black;
      border-right: 2px solid black;
      border-left: 2px solid black;
      border-radius: 4px;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      background-color: white;
      width: 100%;
      padding: 10px calc(0.5em + 9px) calc(0.5em + 9px);
    }
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      list-style: none;
      white-space: nowrap;
    }
  }

  .glide--swipeable {
    cursor: default;
  }

  // .glide {}
  // .glide__track {}

  .glide__slides {
    display: none;
    overflow: visible;
  }

  .glide__slide {
    display: inline-block;
    border: 2px solid transparent;
    border-radius: 2px;
    vertical-align: middle;
    background-color: $gray-100;

    &:hover {
      border-color: $black;
    }
  }

  .glide__arrows {
    display: none;
  }

  .glide__arrow {
    border-radius: 50px;
    background-color: $white;
    border: none;
    color: $black;
    padding: 5px;

    &:hover {
      background-color: $gray-200;
    }

    &.glide__arrow--right {
      right: -10px;
    }

    &.glide__arrow--left {
      left: -10px;
    }

    &.glide__arrow--disabled {
      cursor: default;
      background-color: $gray-200;
      color: $gray-600;
      opacity: 0.8;
    }

    svg {
      width: 14px;
      height: 12px;
    }
  }
}

.related-item__link {
  display: block;
  height: 100%;
  padding: 5px 0;
  margin: 0 auto;

  &:focus {
    outline-offset: 0;
  }
}

.related-item__image {
  display: block;

  img {
    width: 100%;
    height: 100%;
  }
}
