/* Product Sticker */
.catalog-list-product__sticker {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 18px;
  gap: 10px;

  position: absolute;
  max-width: 250px;
  left: 12px;
  top: 14px;
  border-radius: 999px;

  font-weight: bold;
  font-size: 1.0rem;

  display: flex;
  align-items: center;
  text-align: center;

  flex: none;
  order: 0;
  flex-grow: 0;

  @include media-breakpoint-up(md) {
    font-size: 1.4rem;
    left: 25px;
    top: 29px;
  }
}
