/**
 * SCSS partial for search in the header.
 */

.search-widget {
  position: absolute;
  display: none;
  top: 100%;
  z-index: 1100;
  right: 0;
  height: auto;
  width: 100%;
  background-color: $white;
  border-top: 1px solid $gray-200;
  box-shadow: 0 8px 10px rgba($black, 0.05);

  @include media-breakpoint-up(md) {
    right: 2.7em;
    width: 30%;
    border: 1px solid $gray-200;
    border-radius: 2px;
  }

  &.is-open {
    display: block;
  }

  *:focus {
    outline-color: $purple-500;
  }
}

.search-widget--caret {
  display: none;
  position: absolute;
  height: 10px;
  width: 10px;
  top: -5px;
  right: 3em;
  background-color: $gray-200;
  border-top: 1px solid $gray-200;
  border-left: 1px solid $gray-200;
  transform: rotate(45deg);

  @include media-breakpoint-up(md) {
    display: block;
  }
}

.search-widget__field {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 14px 10px 14px 5px;
  background: $gray-200;
}

.search-widget__label {
  padding: 0 10px 0 5px;
}

.search-widget__input {
  height: 42px;
  width: 100%;
  padding: 5px 10px;
  border: none;
  border-radius: 2px;
  color: $gray-800;

  &::-ms-clear {
    display: none;
  }

  &:focus,
  &:active {
    box-shadow: none;
  }

  @include media-breakpoint-up(md) {
    padding: 6px 15px;
  }
}

.search-widget__close {
  position: absolute;
  top: 2.2em;
  right: 18px;
  transform: translateY(-50%);
  z-index: 2;
  border: 0;
  padding: 0;
  background: none transparent;
  pointer-events: auto;
  cursor: pointer;
  color: $gray-800;

  svg {
    display: block;
    width: 1em;
    height: 1em;
  }
}

.search-widget__result-list {
  padding: 0;
  margin: 0;
  list-style: none;
  overflow-y: scroll;
  max-height: 450px;

  @include media-breakpoint-up(sm) {}
}

.search-widget__result {
  background: $white;
  border-top: 1px solid $gray-200;

  a {
    padding: 15px;
    display: flex;
    align-items: center;
    color: $gray-900;

    img {
      height: auto;
      width: auto;
      max-width: 70px;
      max-height: 40px;
      margin-left: 10px;
    }

    span {
      flex-grow: 1;
    }

    &.is-active,
    &:hover,
    &:focus {
      background-color: $gray-200;
      text-decoration: underline;
    }
  }
}

// Light Theme - match nav .dark-theme
.search-widget--dark-theme {
  .search-widget {
    background-color: $gray-900;
    border-color: $gray-600;
  }

  .search-widget--caret {
    background-color: $gray-900;
    border-top: 1px solid $gray-600;
    border-left: 1px solid $gray-600;
  }

  .search-widget__input {
    background: $gray-900;
    color: $white;
  }

  .search-widget__clear {
    color: $white;
  }
}
