
.constraint-list {
  list-style: none;
  margin: 0;
  padding: 5px;

  li {
    margin: 0.25em 0;
  }
}

.constraint {
  display: flex;
  color: $gray-900;
  cursor: pointer;

  &:hover {
    text-decoration: none;

    .constraint__label {
      text-decoration: underline;
    }

    .constraint__count {
      text-decoration: none;
      color: $gray-900;
    }
  }
}

.constraint__label {
  @include body-100;
  display: inline-flex;
  flex: 100%;
  margin-top: 1px;
  margin-bottom: 0;
  margin-left: 4px;
  cursor: pointer;
}

.constraint__count {
  display: inline-flex;
  position: relative;
  top: 4px;
  font-size: 1.2rem;
  color: $gray-600;
}

.constraint__checkbox {
  // TODO: Accessible?
  margin: 0;
  visibility: hidden;
}

.constraint__indicator {
  display: inline-flex;
  position: relative;
  width: 16px;
  height: 16px;
  top: 4px;
  border: 2px solid $gray-300;
  border-radius: 2px;
  margin-right: 2px;
  flex: 1 0 16px;
  align-items: center;
  justify-content: center;

  svg {
    visibility: hidden;
    width: 10px;
  }
}


// Variants

.constraint--checkbox {
  display: flex;
  position: relative;

  &:hover {
    .constraint__indicator {
      border-color: $gray-900;
    }
  }
}


// States

.constraint.is-selected {
  .constraint__label {
    font-weight: bold;
  }

  .constraint__indicator {
    border-color: $gray-900;
    background-color: $gray-900;
    color: $white;
    svg {
      visibility: visible;
    }
  }
}

.constraint.is-disabled:not(.is-selected) {
  cursor: none;
  pointer-events: none;
  color: $gray-400;
  font-style: italic;

  .constraint__count {
    color: $gray-400;
  }

  .constraint__indicator {
    border-color: $gray-100;
    background-color: $gray-100;
  }
}
