.aim-master-reminder {
    padding-top: 5%;
    padding-bottom: 15%;
}

.aim-master-reminder__logo {
    margin-bottom: 20px;
}

.aim-master-reminder__header {
    font-size: 2.25rem;
}

.aim-master-reminder__desc {
    margin-bottom: 40px;
}
