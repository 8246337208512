 // ENGINE HERO

.engine-hero {
  background-color: $gray-900;
  min-height: 525px;
  position: relative;

  &.engine-hero--overlay {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: rgba($black, 0.3);
      z-index: 1;
    }

    .engine-hero__content {
      z-index: 100;
    }
  }

  &.engine-hero--page {
    min-height: 400px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: top;
    background-position-x: center;

    @include media-breakpoint-up(sm) {
      min-height: 525px;
    }

    .container {
      min-height: 400px;

      @include media-breakpoint-up(sm) {
        min-height: 525px;
      }
    }

    .engine-hero__title {
      margin-bottom: 0.5em;
    }
  }
}

.engine-hero--dark {
  color: $gray-900;

  .engine-hero__title {
    color: $gray-900;
  }
}

.engine-hero--white {
  color: $white;
}

.engine-number--color {
  color: $orange-500;
}

.engine-hero__content {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  text-align: center;

  @include media-breakpoint-up(sm) {
    width: auto;
  }

  &.engine-hero__content--left {
    transform: translate(0, -50%);
    left: 15px;
    padding-right: 15px;
    text-align: left;

    @include media-breakpoint-up(sm) {
      left: 0;
    }
  }
}

.engine-hero__body {
  max-width: 600px;
  font-size: 1.6rem;
}

.featured-story__primary {
  position: relative;
  height: 540px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;

  @include media-breakpoint-up(sm) {
    height: 640px;
  }
}

.featured-stories--hero .featured-story__primary {
  padding: 15px 20px;
  height: 225px;

  @include media-breakpoint-up(sm) {
    height: 380px;
  }

  @include media-breakpoint-up(md) {
    height: 500px;
    padding: 30px 40px;
  }

  @include media-breakpoint-up(lg) {
    height: 600px;
  }

  @include media-breakpoint-up(xl) {
    height: 700px;
  }
}

.primary-story-header {
  display: inline-block;
  position: relative;
  color: $white;
  z-index: 100;
}

.primary-story-header {

  .engine-hero__body {
    margin: 0 auto;
    font-size: 1.8rem;
  }
}

.engine-hero__title {
  color: $white;
}

.primary-story-header {
  .engine-hero__title {
    margin: 0 0 10px;
    font-size: 2.8rem;

    @include media-breakpoint-up(sm) {
      font-size: 4.4rem;
    }

    @include media-breakpoint-up(lg) {
      font-size: 5.4rem;
    }
  }
}

.featured-stories__video {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  height: 100%;
  width: auto;
  z-index: 50;

  @include media-breakpoint-up(md) {
    display: block;
  }

  @include media-breakpoint-up(xl) {
    height: auto;
    width: 110%;
  }
}

.story-header--background {
  background-color: transparent;
  padding: 0;

  @include media-breakpoint-up(sm) {
    background-color: rgba(0, 0, 0, .5);
    padding: 30px;
  }
}

.title-beta {
  display: inline-block;
  margin-left: 10px;
  vertical-align: super;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 35%;
  font-style: super;
  color: $orange-500;
}

.download-text {
  color: $gray-500;
}

.engine-download__buttons {
  display: inline-block;
  font-size: 1.2rem;
  color: $gray-500;

  + .engine-download__buttons {
    margin-left: 10px;
  }

  @include media-breakpoint-up(sm) {
    + .engine-download__buttons {
      margin-left: 15px;
    }
  }

  p {
    margin-top: 10px;
    opacity: .5;
  }

  .button {
    min-width: 150px;
  }

  *:focus {
    outline-color: $purple-300;
  }
}

// SUMMARY BANNER

.engine-summary {
  background-color: $gray-200;
  border-bottom: solid 1px $gray-200;
  border-top: solid 1px $gray-200;
}

.engine-summary__content {
  text-align: center;
  padding: 50px 0;
  color: $gray-900;
}

.engine-summary__title {
  display: inline-block;
  vertical-align: top;
  width: 100%;
}

.engine-summary__text {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  margin-top: 20px;
  padding-left: 50px;
}

// FEATURES

.item--background-color {
  background-color: $gray-900;
}

.feature-header-content--color {
  color: $white;
}

// CLOUDSYNC

.item--cloudsync {
  background-color: $gray-200;
}

.cloudsync-portal--content-wrapper {
  text-align: left;
  padding-left: 36%;
  padding-top: 7%;
}

.cloudsync-portal {
  position: relative;
  background-color: $gray-200;
  padding-top: 50px;
  height: 566px;
}

// .cloudsync-portal--center {

// }

.developer-header__title {
  margin-top: 20px;
  margin-bottom: 0.5em;
}

.developer-header__body {
  max-width: 900px;
  margin: auto;

  @include media-breakpoint-up(md) {
    max-width: 900px;
    margin: auto;
  }
}

.cloudsync-portal__cta {
  margin-top: 50px;
  margin-bottom: 50px;
  text-align: left;
}

.cloudsync-portal__learn {
  padding-right: 50px;
  padding-left: 50px;
}

.cloudsync-portal__img {
  width: 90%;
  display: inline-block;

  @include media-breakpoint-up(md) {
    width: 70%;
  }
}

.cloudsync-img-wrapper {
  position: absolute;
  display: inline-block;
  top: 50%;
  transform: translateY(-50%);
}

.cloudsync-img-wrapper--desktop {
  right: 70%;
}

.cloudsync-img-wrapper--laptop {
  left: 70%;
}

// DEVELOPER SECTION

.dev-portal {
  background-color: $gray-200;
  padding-top: 50px;
}

.dev-portal--center {
  text-align: center;
  color: $gray-900;
}

.developer-header__title {

  @include media-breakpoint-up(md) {
    margin-top: 20px;
    margin-bottom: 0.5em;
  }
}

.developer-header__body {

  @include media-breakpoint-up(md) {
    margin-right: 100px;
    margin-left: 100px;
  }
}

.dev-portal__cta {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.dev-portal__learn {
  padding-right: 50px;
  padding-left: 50px;
}

.dev-portal__img {
  width: 90%;
  display: inline-block;

  @include media-breakpoint-up(md) {
    width: 70%;
  }
}

.img-wrapper {
  text-align: center;
}


// OLED SECTION

.oled-content-wrapper {
  padding-left: 60%;
}

.oled-img {
  position: relative;
}

.oled-img__700 {
  width: 83%;
  left: -300px;
  top: -50px;
  position: absolute;
}

.oled-callout {
  background-color: $white;
  padding: 200px 0 200px;
  border-bottom: solid 1px $gray-500;
}

.oled-title {
  text-align: left;
  padding-right: 150px;
}

.oled-body {
  text-align: left;
  margin-right: 100px;
  margin-bottom: 50px;
}

// GAMESENSE HERO

.gamesense-banner-hero {
  min-height: 525px;
  background: transparent url('../img/cage-banner.jpg') left center no-repeat;
  background-size: cover;
}

.gamesense-banner__content {
  color: $white;
}

// TILE GRID

// BUTTONS

// SUPPORTED GAMES

.game-title--padding {
  padding-left: 200px;
}

.game-support {
  padding: 3em 0 3em;
  background-color: $white;
  text-align: center;
}

.game-tile {
  max-width: 100%;
  border-radius: 2px;
  margin-bottom: 20px;
  transition:
  transform  150ms ease-in-out,
  box-shadow 150ms ease-in-out;
}

.game-tile:hover {
  transform: scale(1.025);
  box-shadow: 0 8px 10px -6px rgba(0, 0, 0, .8);
}

.game-tile--your-game {
  padding: 80px;
  background-color: $gray-200;
  border: 3px dotted $gray-400;
  border-radius: 2px;
  transition: border-color 150ms ease-in-out;
}

.game-tile--your-game:hover {
  border-color: $gray-700;
  text-decoration: none;
}

.your-game--link {
  text-decoration: none;
}

.your-game--link:hover {
  text-decoration: none;
}

.your-game--text {
  font-size: 2.4rem;
  color: $gray-700;
  text-align: center;
}


// VIDEO GALLERY

.video-gallery-title {
  margin-bottom: 70px;
}

// CLOUDSYNC POSITIONING

.body--position {


  @include media-breakpoint-up(md) {
    margin-right: 500px;
    margin-left: auto;
  }
}

// FOOTS

.foots {
  height: 525px;
  background-color: $gray-900;
}

.foots-placeholder--text {
  color: $gray-200;
  text-align: center;
  padding-top: 20%;
  margin: 0;
}
