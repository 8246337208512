$sm-transition-dur: 300ms !default;
$sm-transition-easing: ease-in-out !default;

.slide-menu {
  position: absolute;
  width: 100%;
  max-width: 100%;
  height: calc(100vh - 50px);
  top: 49px;
  right: 0;
  display: none;
  box-sizing: border-box;
  transition: transform $sm-transition-dur $sm-transition-easing;
  transform: translateY(-110%);
  overflow-y: scroll;
  will-change: transform;
  z-index: 1000;

  .slide-menu__slider {
    position: absolute;
    width: 100%;
    transition: transform $sm-transition-dur $sm-transition-easing;
    transform: translateY(0);
    will-change: transform;
  }

  .slide-menu__main-menu {
    position: relative;
    width: 100%;
    margin: 0;
    padding-left: 0;
    padding-bottom: 86px;
    list-style: none;

    .mobile-navigation__sub-list {
      display: none;
      position: absolute;
      top: 0;
      left: 100%;
      width: 100%;
      list-style: none;
      padding: 0;
    }

    a {
      display: block;

      &:focus {
        outline-offset: -8px;
      }
    }
  }

  a {
    cursor: pointer;
  }
}
